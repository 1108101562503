<template>
	<div style="min-height:90vh;" class="d-flex text-center mt-5 mx-5">
		<b-row align="center" align-v="center">
			<b-col class="text-center" cols="12">
				<h1 class="font-weight-light mb-4">
					{{getErrorMessageTitle}}
				</h1>
				<h6 class="font-weight-light">
					{{getErrorMessage}}
				</h6>
			</b-col>
		</b-row>
	</div>
</template>


<script>
	import { mapGetters } from 'vuex'
	export default{
		computed: {
			...mapGetters('general', ['getErrorMessageTitle','getErrorMessage',]),
		}
	}
</script>
