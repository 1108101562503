<template>
  <div :class="[getTemplate]" :style="cssVars">
    <b-overlay v-if="sendingQuote">
      <b-row
        align="center" align-v="center"
      >
        <b-col cols="12" style="min-width:100vh; min-height:100vh;" class="d-flex align-items-center">
          <loading-animation message="Procesando su solicitud..."/>
        </b-col>
      </b-row>
    </b-overlay>
    <div v-else>
      <div v-if="Object.values(vitalDataLoaded).every(data => data)">
        <div v-if="!atLeastOneVitalResponseFailed">
          <b-row class="justify-content-md-center mx-1" v-if="!formSubmissionResponded" align-v="stretch" >
            <b-col
              v-if="!noTitle"
              cols="12"
              class="text-center"
            >
              <h1>{{ title }}</h1>
              <h5>{{ subtitle }}</h5>
            </b-col>
            <card v-for="opportunity in getOpportunitiesInfo" :key="opportunity.id"
              :first="opportunity.id === getOpportunitiesInfo[0].id && ['cuadrado'].includes(getTemplate)"
              :projectInfo="opportunity.opportunityDataProjectJSON"
              :assetId="opportunity.id"
              :project-id="opportunity.descuentoGrupo[0].grupoBienes[0].proyecto"
              :opportunityNumber="opportunity.numeroDeBien"
              :assetType="opportunity.tipoBien"
              :assetTypology="opportunity.tipo"
              :assetSize="opportunity.superficieUtil"
              :totalSize="opportunity.superficieTotal"
              :terraceSize="opportunity.superficieTerraza"
              :roomsAmount="opportunity.numeroDeDormitorios"
              :bathroomsAmount="opportunity.numeroDeBanos"
              :discountInfo="setDiscountInfo(opportunity.descuentoGrupo)"
              :internetPrice="opportunity.bienMaestroDescuento"
              :normalPrice="opportunity.valorLista"
              :imageURL="opportunity.urlImagenDelBienAComercializarUno"
              :authorizedDiscount="opportunity.descuentoAutorizadoBien"
              :notAuthorizedDiscount="opportunity.descuentoNoAutorizadoBien"
              @button-action="modalVisibility"
            />
            <transition name="modal" v-if="showModal">
              <div class="modal-mask">
                <div class="modal-wrapper" @click="showModal = false">
                  <div class="modal-container" @click.stop>
                    <div class="modal-body" id="mobysuite-opportunities-modal">
                      <quote-form :form-type="modalType" @close-modal="hideModal"></quote-form>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </b-row>
          <div v-else>
            <view-error v-if="showError" @close-modal="hideModal"/>
            <view-success v-else @close-modal="hideModal"/>
          </div>
        </div>
        <b-row class="justify-content-md-center" v-else>
          <server-error />
        </b-row>
      </div>
      <b-overlay v-else>
        <b-row
          align="center" align-v="center"
        >
          <b-col cols="12" style="min-width:100vh; min-height:100vh;" class="d-flex align-items-center">
            <loading-animation message="Cargando oportunidades..."/>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Card from '@/components/Card.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import ServerError from '@/views/ServerError.vue'
import QuoteForm from '@/components/QuoteForm.vue'
import ViewSuccess from '@/views/ViewSuccess.vue'
import ViewError from '@/views/ViewError.vue'

export default {
    name: 'Main',
    components: {
      Card,
      LoadingAnimation,
      ServerError,
      QuoteForm,
      ViewSuccess,
      ViewError,
    },
    data() {
      return {
        templateName: null,
        showModal: false,
        modalType: '',
      }
    },
    methods: {
      modalVisibility(value){
        this.modalType = value
        this.showModal = true
      },
      hideModal(){
        this.showModal = false
      },
      setDiscountInfo(discountInfo){
        return discountInfo.find(element => element["tipoDescuento"] == "GRUPO_BIENES")
      },
    },
    computed: {
      ...mapGetters('opportunities', ['getOpportunitiesInfo']),
      ...mapGetters('general', ['getTemplate']),
      ...mapState('general', ['atLeastOneVitalResponseFailed', 'vitalDataLoaded', 'noTitle', 'themeColors', 'title', 'subtitle']),
      ...mapState('form', ['formSubmissionResponded', 'sendingQuote', 'showError']),
        cssVars() {
            let cssVars = {
              '--primary-color': this.themeColors.primaryColor,
              '--secondary-color': this.themeColors.secondaryColor
            }
            return cssVars
        },
    }
}
</script>
