<template>
  <b-container class="form-sm" fluid="md">
    <b-row>
      <b-col cols="12">
        <div style="margin-bottom:20px"><span style="font-size: 32px;">{{ discountInfo.descripcion }}</span>
          <span class="discount text-center" v-if="discountInfo.montoDescuento > 0">
            <span class=" pt-2 text-white bg-customer text-center" :style="{backgroundColor: setColor(discountInfo.color)}">
              <span class="discount-number font-weight-bold">{{setDiscountValue()}}{{setDiscountText()}} OFF</span>
            </span>
          </span>
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <b-row>
          <b-col cols="6" md="12">
            <image-dialog v-if="images.assetImage == null || images.assetImage" :thumb="`${getImageURL(selectedOpportunity.id)}`" :full="`${getImageURL(selectedOpportunity.id)}`" :full-width="745" :full-height="400" @hide-element="changePosition" @image-visibility="setImageVisibility('asset')"></image-dialog>
          </b-col>
          <b-col cols="6" md="12" v-if="getProjectData.image && getProjectData.image !== 'https://s3.amazonaws.com/gestion.mobysuite/'">
            <image-dialog v-if="images.projectImage == null || images.projectImage" :thumb="`${getProjectData.image}`" :full="`${getProjectData.image}`" :full-width="745" :full-height="400" @hide-element="changePosition" @image-visibility="setImageVisibility('project')"></image-dialog>
          </b-col>
        </b-row>
        <p class="h5 mt-4 font-weight-bold">{{getProjectData.nombreProyecto}}</p>
        <p class="small text-muted">
          Dirección: {{getProjectData.direccion.direccion ? getProjectData.direccion.direccion : `${getProjectData.direccion.calle}` }} <br>
          Piso: {{selectedOpportunity.piso}}<br>
          Tipología: {{selectedOpportunity.tipo}}<br>
          Orientación: {{selectedOpportunity.orientacion}}<br>
          Sup. Total: {{new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(selectedOpportunity.superficieTotal)}} m<sup>2</sup>
        </p>
      </b-col>
      <b-col cols="12" lg="9" class="hidden-data">
        <p class="h4 font-weight-bold">Resumen</p>
        <p  v-if="getUfValue" class="h5">Valor UF: ${{ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(getUfValue)}}</p>
        <table class="mb-3 w-100">
          <tr>
            <td class="h5 font-weight-bold">Unidades</td>
              <td class="text-right">
                <b-button-group size="lg" class="mt-2">
                  <b-button variant="outline-customer" :style="cssVars" :class="{'w-50': true, 'active': (currency == 'UF')}" @click="setCurrency('UF')">UF</b-button>
                  <b-button variant="outline-customer" :style="cssVars" :class="{'w-50': true, 'active': (currency == 'CLP')}" @click="setCurrency('CLP')">$</b-button>
                </b-button-group>
              </td>
          </tr>
        </table>
        <p class="small text-muted"> * Beneficios obtenidos al reservar</p>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr :style="cssVars" class="primary-table-header text-white">
              <th scope="col">Unidad</th>
              <th scope="col" class="text-right">Precio ({{currency}})</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">{{assetName}}</th>
                <td class="text-right">{{ setAssetPrice(selectedOpportunity.valorLista) }}</td>
              </tr>
              <template>
                <tr v-for="(asset) in getBAssociatedGroup" v-bind:key="'asset'+asset.id">
                  <th scope="row">{{`${asset.tipoBien} ${asset.numeroDeBien}`}}</th>
                  <td class="text-right">{{ setAssetPrice(asset.valorLista) }}</td>
                </tr>
              </template>
              <template>
                <tr v-for="(asset) in getOpportunitiesSubAssets" v-bind:key="'asset'+asset.id">
                  <th scope="row">{{`${asset.tipoBien} ${asset.numeroDeBien}`}}</th>
                  <td class="text-right">{{ setAssetPrice(asset.valorLista) }}</td>
                </tr>
              </template>
              <template v-if="getAddedAssets">
                <tr v-for="(asset) in getAddedAssets" v-bind:key="'asset'+asset.id">
                  <th scope="row">{{`${asset.tipoBien} ${asset.numeroDeBien}`}}</th>
                  <td class="text-right">{{ setAssetPrice(asset.valorLista) }}</td>
                </tr>
              </template>
              <tr v-if="selectedOpportunity.descuentoOportunidad > 0" style="color: red">
                <th scope="row" style="display:flex;">
                  DESCUENTO
                  <p class="ml-1" id="popover-target-1">*</p>
                  <b-popover target="popover-target-1" triggers="hover" placement="right" variant="dark" container="mobysuite-opportunities-modal">
                    Aplicado al precio del bien principal
                  </b-popover>
                </th>
                <td class="text-right">{{ setAssetPrice(selectedOpportunity.descuentoOportunidad) }}<small style="padding-left: 5px; color: grey" v-if="setDiscountText() == '%'">({{setDiscountValue()}} {{setDiscountText()}})</small></td>
              </tr>
              <tr>
                <th scope="row">TOTAL</th>
                <td class="text-right">{{setTotal(true)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      <div style="display: block;">
        <b-row class="mt-4 mb-3 text-center" style=" display:block;">
          <p v-if="getAssetInfo.associationStatus == 'BIEN_DESVINCULADO' || getOpportunityAssetsValidation" style="font-style: italic;">
            El bien que ha seleccionado no tiene estacionamientos ni bodegas
            disponibles
          </p>
          <b-col v-else>
            <asset-map v-if="subAssetsRequirements.warehouse.set || subAssetsRequirements.parkingLot.set" :color="themeColors.primaryColor" :initialAssets="notSelectableAssets"></asset-map>
          </b-col>
        </b-row>
          <b-overlay :show="loadingPaymentPlan" rounded="sm" :opacity="1.0" :class="`${loadingPaymentPlan ? 'mt-5 mb-3' : ''}`">
            <table v-if="paymentData.status == 'success'" class="table mb-3 w-100 mt-3">
              <thead>
                <tr>
                  <th colspan="2" scope="col" class="h5 font-weight-bold">Plan de pago</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">Precio venta final</th>
                <td class="text-right">{{ setTotal(true) }}</td>
                </tr>
                <tr :style="cssVars" class="focus-color text-white">
                  <th scope="row">Reserva</th>
                  <td class="text-right">{{ bookingAmount(true) }}</td>
                </tr>
                <tr>
                <th scope="row">Pie a pagar</th>
                <td class="text-right">{{ currency == 'UF' ? `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(paymentData.funding.adjustmentValue)} UF` : `$ ${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(Math.round(paymentData.funding.localCurrencyValue))}` }}</td>
                </tr>
                <tr>
                <th scope="row">Crédito hipotecario</th>
                <td class="text-right">{{ currency == 'UF' ? `${ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(paymentData.mortgageCredit.adjustmentValue)} UF` : `$ ${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(Math.round(paymentData.mortgageCredit.localCurrencyValue))}`}}</td>
                </tr>
              </tbody>
            </table>
            <div  style="text-align:center;" v-if="paymentData.status == 'error'">
              <p>Lo sentimos, pero no logramos calcular el plan de pago</p>
            </div>
            <template #overlay>
              <div class="text-center">
                <p>Aguarde mientras calculamos el plan de pago del negocio</p>
                <b-spinner
                  variant="secondary"
                ></b-spinner>
              </div>
            </template>
        </b-overlay>
      </div>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-3 hidden-data" v-if="formType" >
      <b-col>
        <p class="h5 font-weight-bold">Realiza tu {{ formType == 'quote' ? getSlang.formData.quoteName.toLowerCase() : 'reserva' }}</p>
        <p class="small text-muted">Por favor, complete los siguientes campos.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetMap from '@/components/AssetMap.vue'
import ImageDialog from '@/components/ImageDialog.vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'QuoteForm',
  components: {
    AssetMap,
    ImageDialog
  },
  props: {
    formType: { type: String, default: 'quote' },
  },
  data(){
    return{
      currency: 'UF',
      phoneValidationStatus: null,
      bookingData: {
        currency: 'UF',
        value: 0,
      },
      images: {
        projectImage: null,
        assetImage: null,
      },
      subAssetsGlobalMaxNumber: null,
      subAssetsGlobalMinNumber: null,
    }
  },
  created() {
    this.setProjectId(this.getProjectData.id)
    this.setMaxAndMins()
    this.fetchAssetMaps()
    this.fetchAssetsData()
    this.setAddedAssets([])
    this.setAddedParkingLots([])
    this.setAddedWarehouses([])
    this.setIsButtonDisabled(false)
    this.bookingData.currency = this.selectedOpportunity.opportunityDataProjectJSON.montoReservaJSON.tipoCalculo == 'PORCENTAJE' ||
    this.selectedOpportunity.opportunityDataProjectJSON.montoReservaJSON.tipoCalculo == 'VALOR_AJUSTE' ? 'UF' : 'CLP'
    this.bookingData.value = this.selectedOpportunity.opportunityDataProjectJSON.montoReservaJSON.montoReserva
    this.setloadingPaymentPlan(true)
    this.fetchPaymentPlan()
    this.setAssociatedLimits()
  },
  computed: {
    ...mapGetters(
      'general', [
      'getProjectData',
      'getSlang',
      'getUfValue',
      'getIsMinParkingLotsNumberFulfilled',
      'getIsMinWarehouseNumberFulfilled',
      'getWarehousesMinNumber',
      'getParkingLotsMinNumber',
      'getTemplate'
    ]),
    ...mapGetters('opportunities', ['getImageURL', 'getOpportunitiesSubAssets', 'getAssetInfo', 'getOpportunityAssetsValidation', 'getBAssociatedGroup']),
    ...mapState('opportunities', ['selectedOpportunity', 'propertyNames']),
    ...mapState('general', ['customer', 'themeColors', 'countryCode', 'paymentData', 'loadingPaymentPlan', 'version']),
    ...mapState('form', [
      'fetchedComunas',
      'fetchedAges',
      'fetchedProfessions',
      'fetchedPurchaseDestinations',
      'fetchedSexs',
      'fetchedRentRanges',
      'optionalFields',
      'subAssetsRequirements']),
    ...mapGetters('assets', ['getAddedAssets','getAddedParkingLots','getAddedWarehouses']),
    ...mapGetters('form', ['getIsButtonDisabled',]),
    assetName(){
      return `${this.selectedOpportunity.tipoBien} ${this.selectedOpportunity.numeroDeBien}`
    },
    cssVars() {
      return {
        '--primary-color': this.themeColors.primaryColor,
        '--secondary-color': this.themeColors.secondaryColor,
      }
    },
    popoverConfig() {
      return {
        html: true,
        container: 'mobysuite-opportunities-modal',
        title: () => {
          return 'CONDICIONES DE RESERVA ONLINE'
        },
        content: () => {
          return '<table><tr><td>Dentro del plazo de 10 días hábiles me comprometo a suscribir la correspondiente promesa de compraventa. El no cumplimiento, será considerado DESISTIMIENTO. En este caso la Inmobiliaria no reembolsará el monto por concepto de reserva y podrá disponer de la propiedad para la venta. </td></tr><tr><td>El pie de la vivienda debe estar cancelado al momento de firmar la escritura.</td></tr><tr><td> <strong>Gastos operacionales:</strong></td></tr><tr><td> Se cancelarán a la institución que otorga el crédito y el monto será determinado por la institución crediticia. </td></tr><tr><td>En caso de pago contado, se cancelan a la Inmobiliaria. Los gastos operacionales NO están incluidos en el Precio.</td></tr>'
        }
      }
    },
    notSelectableAssets() {
      let assets = []
      assets = assets.concat(this.getBAssociatedGroup.map( asset => asset.id))
      assets = assets.concat(this.getOpportunitiesSubAssets.map( asset => asset.id))
      return assets
    },
    discountInfo(){
        return this.selectedOpportunity.descuentoGrupo.find(element => element["tipoDescuento"] == "GRUPO_BIENES")
      },
  },
  methods: {
    ...mapActions('assets', ['fetchAssetsData']),
    ...mapActions('general', ['fetchAssetMaps', 'fetchPaymentPlan']),
    ...mapMutations('general', [
      'setProjectId',
      'setMaxAndMins',
      'setIsMinWarehouseNumberFulfilled',
      'setIsMinParkingLotsNumberFulfilled',
      'setloadingPaymentPlan',
    ]),
    ...mapMutations('assets', [
      'setAddedAssets',
      'setAddedParkingLots',
      'setAddedWarehouses',
    ]),
    ...mapMutations('form', ['setIsButtonDisabled','setQuoteStatus', 'setOptionalFields']),
    setDiscountText() {
        if(this.discountInfo.tipoMonto=='PORCENTAJE' || this.discountInfo.montoDescuento=='MONTO_DESCUENTO_BIEN' || this.discountInfo.tipoMonto=='MONTO_DESCUENTO_NO_AUTORIZADO_BIEN')
            return '%'
        else if (this.discountInfo.tipoMonto=='CONSTANTE')
            return ' UF'
        else if (this.discountInfo.tipoMonto=='MONTO')
            return ' CLP'
    },
    setDiscountValue() {
        if(this.discountInfo.tipoMonto=='PORCENTAJE')
            return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.discountInfo.montoDescuento * 100)}`
        else if (this.discountInfo.tipoMonto=='CONSTANTE')
            return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.discountInfo.montoDescuento)}`
        else if (this.discountInfo.tipoMonto=='MONTO')
            return `$${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.discountInfo.montoDescuento)}`
        else if (this.discountInfo.tipoMonto=='MONTO_DESCUENTO_BIEN')
            return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.selectedOpportunity.descuentoAutorizadoBien * 100)}`
        else if (this.discountInfo.tipoMonto=='MONTO_DESCUENTO_NO_AUTORIZADO_BIEN')
            return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.selectedOpportunity.descuentoNoAutorizadoBien * 100)}`
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('homepage')

      const self = this
      const version = this.version == 2 ? 'v3' : 'v2'
      this.axios
        .post(`${version}/quotes/validate-captcha-token`, {
          token: token,
        })
        .then(response => {
          if (response.data.valid) {
            let data = self.formData
            if(!this.optionalFields[this.formType].receiveNews.set)
              delete data["receiveNews"]
            if (self.formType == 'quote')
            {
              //Clean object
              delete data["terms"]
              delete data["address"]
              delete data["apartment"]
              delete data["city"]
              self.$emit('close-modal')
              self.sendForm(data)
            }
            else {
              //Clean object
              // delete reservation["rentRange"]
              // delete reservation["profession"]
              // delete reservation["ageRange"]
              // delete reservation["destinationPurchase"]
              // delete reservation["sex"]
              // //must rename some fields accordig to service
              // delete reservation["fName"]
              // delete reservation["lName"]
              // delete reservation["phone"]
              // reservation = {
              //   ... reservation,
              //   ...{
              //     name: self.formData.fName,
              //     lastname: self.formData.lName,
              //     cellphone: self.formData.phone
              //   }
              // }
              self.$emit('close-modal')
              self.reservationToken(data)
            }
          } else {
            self.setDataError()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    setCurrency(type){
      this.currency = type
    },
    setAssetPrice(price, format = true){
      let amount = format ? this.currency == 'UF' ? price : price*this.getUfValue : price
      let formattedPrice = new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format((this.currency == 'CLP' ? Math.round(amount) : amount))
      return `${this.currency == 'CLP' ? '$' : ''} ${formattedPrice} ${this.currency == 'UF' ? 'UF' : ''}`
    },
    setTotal(format){
      let allAssets = this.getAddedAssets.concat(this.getBAssociatedGroup)
      allAssets.push(this.selectedOpportunity)
      allAssets = allAssets.concat(this.getOpportunitiesSubAssets)
      allAssets = allAssets.map( (asset) => parseFloat(asset.bienMaestroDescuento ? asset.bienMaestroDescuento : asset.valorLista))
      const total = allAssets.reduce( (previous, current) => previous + current, 0 )
      return format ? this.setAssetPrice(total) : this.currency == 'UF' ? total : total*this.getUfValue
    },
    ...mapActions('form', ['sendForm', 'reservationToken']),
    async submitForm() {
      await this.validateMins()
      this.$v.$touch()
      if (!this.$v.$invalid && !this.getIsButtonDisabled) {
          this.setQuoteStatus(true)
          this.recaptcha()
      }
    },
    validateMins(){
      if(((this.getAddedWarehouses.length >= this.getWarehousesMinNumber ||
        this.getWarehousesMinNumber === null) && this.subAssetsGlobalMinNumber == null) || (this.subAssetsGlobalMinNumber != null && (this.getAddedAssets.length >= this.subAssetsGlobalMinNumber)))
        {
          this.setIsMinWarehouseNumberFulfilled(true)
          this.setIsButtonDisabled(false)
        }
      else
      {
        this.setIsMinWarehouseNumberFulfilled(false)
        this.setIsButtonDisabled(true)
      }

      if(((this.getAddedParkingLots.length >= this.getParkingLotsMinNumber ||
        this.getParkingLotsMinNumber === null) && this.subAssetsGlobalMinNumber == null) || (this.subAssetsGlobalMinNumber != null && (this.getAddedAssets.length >= this.subAssetsGlobalMinNumber)))
        {
          this.setIsMinParkingLotsNumberFulfilled(true)
          this.setIsButtonDisabled(false)
        }
      else
        {
          this.setIsMinParkingLotsNumberFulfilled(false)
          this.setIsButtonDisabled(true)
        }
    },
    phoneCustomValidation(number, phoneObject){
      this.formData.phone = phoneObject.number
      this.phoneValidationStatus = phoneObject.valid ? true : false
      return phoneObject.valid ? true : false
    },
    bookingAmount(format = false){
      let amount = 0
      if(this.paymentData.status == 'success'){
        amount = this.currency == 'UF' ? this.paymentData.adjustmentValue : this.paymentData.localCurrencyValue
      }

      if (this.currency == 'UF' &&  this.bookingData.currency == 'CLP') { amount = this.bookingData.value / this.getUfValue }
      else if (this.currency == 'CLP' &&  this.bookingData.currency == 'UF') { amount = this.bookingData.value * this.getUfValue }
      else { amount = this.bookingData.value }

      if(format){
        if(this.currency == 'CLP'){
          return `$${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(Math.round(amount))}`
        }else{
          return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(amount)} UF`
        }
      }

      return amount
    },
    changePosition(hide){
      let elements = document.getElementsByClassName('hidden-data')
      if (hide){
        Array.prototype.filter.call(elements, function(element){
          element.style.display = 'none'
        })
      }else {
        Array.prototype.filter.call(elements, function(element){
          element.style.removeProperty('display')
        })
        this.images.projectImage = null
         this.images.assetImage = null
      }
    },
    setImageVisibility(active){
      if(active == 'project'){
       this.images.projectImage = true
       this.images.assetImage = false
      }else{
       this.images.assetImage = true
       this.images.projectImage = false
      }
    },
    setAssociatedLimits(){
      if(this.selectedOpportunity[this.propertyNames.associatedLimits.max]){
        this.subAssetsGlobalMaxNumber = this.selectedOpportunity[this.propertyNames.associatedLimits.max]
        this.subAssetsGlobalMinNumber = this.selectedOpportunity[this.propertyNames.associatedLimits.min]
      } else{
        this.subAssetsGlobalMaxNumber = null
        this.subAssetsGlobalMinNumber = null
      }
    },
    setColor(color){
      switch(color){
        case 'Verde':
          return '#008000';
        case 'Rojo':
          return '#E60000';
        case 'Azul':
          return '#0054A6';
        default:
          return '#008000';
      }
    }
  },
}
</script>
<style scoped lang="scss">
  mobysuite-opportunities {
.btn-outline-customer.active, .btn-outline-customer:hover {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-outline-customer {
  color: var(--primary-color) !important;
  background-color: transparent !important;
  border-color: var(--primary-color) !important;
}
.btn-customer.active, .btn-customer:hover {
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border-color: var(--primary-color) !important;
}
.btn-customer {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.primary-table-header{
  background-color: var(--primary-color) !important;
}
.focus-color{
  background-color: var(--secondary-color) !important;
}
.vue-tel-input__error-message {
  color: red;
}
.vue-tel-input--error {
  border-color: red;
  box-shadow: 0 0 0 0.2rem rgb(235 87 87 / 25%) !important;
}
.vue-tel-input--success{
  border-color: #28a745;
}
    .opportunities-img{
      max-height: 270px;
    }
    .bg-customer{
        font-size: 0.9em;
        background-color: green;
        min-width: 75px;
        padding: 5px 25px;
        text-align: center;
        vertical-align: middle;
        border-radius: 42px;
    }
    .discount{
        margin-left: 10px;
    }
    .bordered-table{
        border-right: 1px solid #333;
    }
    .discount-number{
        font-size: 15px;
    }
    .btn-quote{
        color: var(--secondary-color) !important;
        background-color: transparent !important;
        border-color: var(--secondary-color) !important;
    }
    .btn-quote:hover{
        color: #fff !important;
        background-color: var(--secondary-color) !important;
        border-color: var(--secondary-color) !important;
    }
    .btn-reservation {
        color: #fff !important;
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }
    .image{
        position:relative;
        //overflow:hidden;
        //padding-bottom:100%;
    }
    .image img{
        //position:absolute;
    }
  }
</style>
