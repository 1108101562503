import Vue from 'vue'
const state = {
  fetchedAssets: [],
  addedAssets: [],
  addedParkingLots: [],
  addedWarehouses: [],
  propertyNames: {
    assetNumber: 'numeroDeBien',
    roomsAmount: 'numeroDeDormitorios',
    bathroomsAmount: 'numeroDeBanos',
    assetType: 'tipoBien',
    floor: 'piso',
    id: 'id',
    imageURLOne: 'urlImagenUno',
    imageURLTwo: 'urlImagenDos',
    subAsset: 'bConjunto',
    totalArea: 'superficieTotal',
    terraceArea: 'superficieTerraza',
    utilArea: 'superficieUtil',
    orientation: 'orientacion',
    price: 'valorLista',
    type: 'tipo',
    masterAsset: 'bMaestro',
    projectId: 'proyecto',
    associationType: 'tipoAsociacion',
    subAssetTypes: ['BODEGA', 'ESTACIONAMIENTO'],
  },
  useSecondaryImage: false,
  typeNames: {
    apartment: 'DEPARTAMENTO',
    parkingLot: 'ESTACIONAMIENTO',
    warehouse: 'BODEGA',
  },
}
const mutations = {
  setAssets: (state, payload) => (state.fetchedAssets = payload),
  setAddedAssets: (state, data) => (state.addedAssets = data),
  setAddedParkingLots: (state, data) => (state.addedParkingLots = data),
  setAddedWarehouses: (state, data) => (state.addedWarehouses = data),
  setImagePreference: (state, value) => {state.useSecondaryImage = value},
  resetSecondaryAssetsToQuote: state => {
    state.addedAssets = []
    state.addedParkingLots = []
    state.addedWarehouses = []
  },
  setSubAssetsTypes: (state, data) => {
    if (data.action == 'remove') {
      state.propertyNames.subAssetTypes = state.propertyNames.subAssetTypes.filter(
        assetType => assetType != data.type
      )
    } else {
      if(!state.propertyNames.subAssetTypes.includes(data.type))
        state.propertyNames.subAssetTypes.push(data.type)
    }
  },
}
const actions = {
  async fetchAssetsData({ state, commit, rootState }) {
    const version = rootState.general.version == 2 ? 'v3' : 'v2'
    await Vue.axios
    .get(`${version}/quotes/list-available-assets`, {
      params: {
        real_estate: rootState.general.customer,
        project_id: rootState.general.projectId,
      },
    })
    .then(response => {
      //Remove assets if they're unlinked
      const filteredAssets = response.data.filter( asset => !(asset[state.propertyNames.associationType] && asset[state.propertyNames.associationType] == 'BIEN_DESVINCULADO'))
      if (!filteredAssets) {
        console.log('Error trying to get assets')
        return
      }
      commit('setAssets', filteredAssets)
    })
    .catch(error => {
      console.log(error)
    })
  },
}

const getters = {
  getAssets: (state, getters) => {
    let assets = getters.getOpportunityAssociatedSubAssets.length > 0 ? getters.getOpportunityAssociatedSubAssets
      : state.fetchedAssets
    return assets.filter(asset => state.propertyNames.subAssetTypes.includes(
      asset[state.propertyNames.assetType]
    ))
  },
  getAddedAssets: state => state.addedAssets,
  getAddedParkingLots: state => state.addedParkingLots,
  getAddedWarehouses: state => state.addedWarehouses,
  getImageURL: (state, getters) => id => {
    const asset = getters.getAssetById(id)
    const URLValue = state.useSecondaryImage
      ? asset.urlImagenDos || null
      : asset.urlImagenUno || null
    const imageURL = URLValue
      ? URLValue
      : 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png'
    return imageURL
  },
  getAssetById: (state, getters) => id => {
    let asset = getters.getAssets.find(asset => asset[state.propertyNames.id] == id);
    return (asset ? asset : getters.getAssets.find(asset => asset[state.propertyNames.assetType]+asset[state.propertyNames.assetNumber] == id));
  },
  getSelectedAssetsId: (state, getters, rootState, rootGetters) => {
    let assetIDs = []
    assetIDs.push({ id: rootState.opportunities.selectedOpportunity.id})
    if (rootState.opportunities.selectedOpportunity[state.propertyNames.subAsset]){
        rootState.opportunities.selectedOpportunity[state.propertyNames.subAsset].map(subAsset => {
        assetIDs.push({ id: subAsset[state.propertyNames.id] })
      })
    }
    state.addedAssets.map(subAsset => {
      assetIDs.push({ id: subAsset[state.propertyNames.id] })
    })
    rootGetters['opportunities/getOpportunitiesSubAssets'].map(subAsset => {
      assetIDs.push({ id: subAsset[state.propertyNames.id] })
    })
    return assetIDs
  },
  getSubAssetsNames: state => state.propertyNames.subAssetTypes,
  getOpportunityAssociatedSubAssets: (state, getters, rootState) => {
    const opportunityIsAssociated = rootState.opportunities.selectedOpportunity[state.propertyNames.associationType]
    ? rootState.opportunities.selectedOpportunity[state.propertyNames.associationType] == 'BIEN_ASOCIADO'
    : false
    if(opportunityIsAssociated){
      const asset = state.fetchedAssets.find(
        asset => asset[state.propertyNames.id] == rootState.opportunities.selectedOpportunity[state.propertyNames.id]
      )
      return asset ? asset[rootState.opportunities.propertyNames.associatedSubAssets] : []
    }

    return []
  } ,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
