<template>
	<b-row v-if="getTemplate == 'cuadrado'" align-v="center">
		<b-col
      v-if="first"
			cols="12"
		>
      <div class="opportunities-big-text h3 mb-2 font-weight-bold">UF {{`${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(internetPrice)}`}}* (-{{ discountValue }}{{ discountText }})</div>
      <div
        class="normal-price h4 mb-2"
        v-if="normalPrice > 0"
      >
        Antes UF {{`${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(normalPrice)}`}}
      </div>
      <template v-if="discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('DEPARTAMENTO')">
        <div v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('ESTACIONAMIENTO') || !discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('BODEGA')">*no incluye <span v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('ESTACIONAMIENTO')">estacionamiento</span> <span v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('ESTACIONAMIENTO') && !discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('BODEGA')">ni</span> <span v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('BODEGA')">bodega</span></div>
    </template>
		</b-col>
		<b-col
      v-else
			cols="12"
			class="font-weight-bold"
		>
      <span class="h5 mb-3 font-weight-bold">UF {{`${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(internetPrice)}`}}* </span>
      <span
        class="normal-price h5"
        v-if="normalPrice > 0"
      >
        antes UF {{`${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(normalPrice)}`}}
      </span>
      <template v-if="discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('DEPARTAMENTO')">
        <div class="small mt-2" v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('ESTACIONAMIENTO') || !discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('BODEGA')">*no incluye <span v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('ESTACIONAMIENTO')">estacionamiento</span> <span v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('ESTACIONAMIENTO') && !discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('BODEGA')">ni</span> <span v-if="!discountInfo.grupoBienes.map(asset => asset.tipoBien).includes('BODEGA')">bodega</span></div>
      </template>
		</b-col>
		<b-col
			cols="12"
			class="opportunities-small-text"
			v-if="booking.tipoCalculo!='SIN_CALCULO'"
      style="padding-top: 10px"
		>
		</b-col>
	</b-row>
	<b-row v-else align-v="center">
		<b-col
			cols="12"
			class="font-weight-bold"
		>
    <span class="opportunities-big-text">{{`${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(internetPrice)}`}} UF</span>
    <small
      class="normal-price"
			v-if="normalPrice > 0"
		>
			{{`${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(normalPrice)}`}} UF
		</small>
		</b-col>

		<b-col
			cols="12"
			class="opportunities-small-text"
			v-if="booking.tipoCalculo!='SIN_CALCULO'"
      style="padding-top: 10px"
		>
			<small>Reserva: {{setBookingValue()}}</small>
		</b-col>
	</b-row>
</template>

<script>
export default {
	name: 'Price',
	props: {
		internetPrice: {
			type: Number,
			default: null,
		},
		normalPrice: {
			type: Number,
			default: null,
		},
		booking: {
			type: Object,
			default: null,
		},
    first: {
      type: Boolean,
      default: false,
    },
		discountValue: {
			type: String,
			default: null,
		},
		discountText: {
			type: String,
			default: null,
		},
    discountInfo: {
      type: Object,
      default: null,
    }
	},
	methods: {
		setBookingValue() {
            if (this.booking.tipoCalculo=='PORCENTAJE' || this.booking.tipoCalculo=='VALOR_AJUSTE')
                return `$${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(Math.round(this.booking.montoReserva * this.getUfValue))} CLP`
            else if (this.booking.tipoCalculo=='MONEDA_LOCAL')
                return `$${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(Math.round(this.booking.montoReserva))} CLP`
        }
	},
  computed: {
    getUfValue() {
      return this.$store.getters.getUfValue
        ? this.$store.getters.getUfValue
        : "default";
    },
    getTemplate() {
      return this.$store.getters.getTemplate
        ? this.$store.getters.getTemplate
        : "default";
    },
  }
}
</script>
<style lang="scss">
  mobysuite-opportunities {
    .opportunities-big-text{
      font-size: 17px;
      line-height: 1.5;
    }
  }
</style>
