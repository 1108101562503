<template>
  <b-container class="form-sm" fluid="md">
    <b-row>
      <b-col class="pr-0">
        <button type="button" aria-label="Close" class="close" @click="$emit('close-modal')">×</button>
      </b-col>
    </b-row>
    <modal-data-cuadrado v-if="['cuadrado'].includes(getTemplate)" :form-type="formType"></modal-data-cuadrado>
    <modal-data-default v-else :form-type="formType"></modal-data-default>
    <b-row class="hidden-data" v-if="formType" >
      <form class="col-12" @submit.prevent="submitForm" >
        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <b-form-group>
              <div class="text-left px-1 small text-dark form-label">
                Nombre
              </div>
              <b-form-input size="sm"  v-model.trim="$v.formData.fName.$model" :state="$v.formData.fName.$dirty ? !$v.formData.fName.$error : null"></b-form-input>
              <b-form-invalid-feedback>
                Campo requerido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-lg-6 mb-3">
            <b-form-group>
              <div class="text-left px-1 small text-dark form-label" >
                Apellido(s)
              </div>
              <b-form-input size="sm"  v-model.trim="$v.formData.lName.$model" :state="$v.formData.lName.$dirty ? !$v.formData.lName.$error : null"></b-form-input>
              <b-form-invalid-feedback>
                Campo requerido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <b-form-group>
              <div class="text-left px-1 small text-dark form-label" >
                {{ getSlang.formData.dniName }}
              </div>
              <b-form-input size="sm" @input="rutFormat" v-model.trim="$v.formData.rut.$model" :state="$v.formData.rut.$dirty ? !$v.formData.rut.$error : null"></b-form-input>
              <b-form-invalid-feedback>
                Campo requerido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-lg-6 mb-3">
            <b-form-group>
              <div class="text-left px-1 small text-dark form-label" >
                Email
              </div>
              <b-form-input size="sm"  v-model.trim="$v.formData.email.$model" :state="$v.formData.email.$dirty ? !$v.formData.email.$error : null"></b-form-input>
              <b-form-invalid-feedback>
                Correo inválido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <div class="text-left px-1 small text-dark form-label" >
              Teléfono
            </div>
            <vue-tel-input  @input="phoneCustomValidation"
              wrapperClasses="form-control phone-input"
              :class="`${ $v.formData.phone.$error ? 'vue-tel-input--error' : phoneValidationStatus ? 'vue-tel-input--success' : ''}`"
              :only-countries="[countryCode]" />
            <b-form-input class="d-none" v-model.trim="$v.formData.phone.$model" :state="$v.formData.phone.$dirty ? !$v.formData.phone.$error : null"></b-form-input>
            <b-form-invalid-feedback>
              Teléfono invalido.
            </b-form-invalid-feedback>
          </div>
          <div class="col-lg-6 mb-3" v-if="optionalFields[formType].commune.set">
            <b-form-group>
             <div class="text-left px-1 small text-dark form-label" >
                Comuna {{ optionalFields[formType].commune.required ? '' : '(opcional)' }}
              </div>
              <b-select v-model="formData.commune" :state="$v.formData.commune.$dirty ? !$v.formData.commune.$error : null" :options="fetchedComunas"></b-select>
              <b-form-invalid-feedback>
                Es necesario seleccionar una opción.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-lg-6 mb-3" v-if="optionalFields[formType].ageRange.set">
            <b-form-group>
             <div class="text-left px-1 small text-dark form-label" >
                Edad {{ optionalFields[formType].ageRange.required ? '' : '(opcional)' }}
              </div>
              <b-select v-model="formData.ageRange" :state="$v.formData.ageRange.$dirty ? !$v.formData.ageRange.$error : null" :options="fetchedAges"></b-select>
              <b-form-invalid-feedback>
                Es necesario seleccionar una opción.
              </b-form-invalid-feedback>
            </b-form-group>          </div>
          <div class="col-lg-6 mb-3" v-if="optionalFields[formType].profession.set">
            <b-form-group>
             <div class="text-left px-1 small text-dark form-label" >
                Profesión {{ optionalFields[formType].profession.required ? '' : '(opcional)' }}
              </div>
              <b-select v-model="formData.profession" :state="$v.formData.profession.$dirty ? !$v.formData.profession.$error : null" :options="fetchedProfessions"></b-select>
              <b-form-invalid-feedback>
                Es necesario seleccionar una opción.
              </b-form-invalid-feedback>
            </b-form-group>          </div>
          <div class="col-lg-6 mb-3" v-if="optionalFields[formType].destinationPurchase.set">
            <b-form-group>
             <div class="text-left px-1 small text-dark form-label" >
                Destino de compra {{ optionalFields[formType].destinationPurchase.required ? '' : '(opcional)' }}
              </div>
              <b-select v-model="formData.destinationPurchase" :state="$v.formData.destinationPurchase.$dirty ? !$v.formData.destinationPurchase.$error : null" :options="fetchedPurchaseDestinations"></b-select>
              <b-form-invalid-feedback>
                Es necesario seleccionar una opción.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-lg-6 mb-3" v-if="optionalFields[formType].sex.set">
             <b-form-group>
             <div class="text-left px-1 small text-dark form-label" >
                Sexo {{ optionalFields[formType].sex.required ? '' : '(opcional)' }}
              </div>
              <b-select v-model="formData.sex" :state="$v.formData.sex.$dirty ? !$v.formData.sex.$error : null" :options="fetchedSexs"></b-select>
              <b-form-invalid-feedback>
                Es necesario seleccionar una opción.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-lg-6 mb-3" v-if="optionalFields[formType].rentRange.set">
            <b-form-group>
             <div class="text-left px-1 small text-dark form-label" >
                Rango de renta {{ optionalFields[formType].rentRange.required ? '' : '(opcional)' }}
              </div>
              <b-select v-model="formData.rentRange" :state="$v.formData.rentRange.$dirty ? !$v.formData.rentRange.$error : null" :options="fetchedRentRanges"></b-select>
              <b-form-invalid-feedback>
                Es necesario seleccionar una opción.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div v-if="formType == 'reservation' && optionalFields[formType].direction.set" class="col-lg-6 mb-3">
            <b-form-group>
              <div class="text-left px-1 small text-dark form-label" style="position:absolute;top:-10px;left:35px;background-color:white !important; z-index:100">
                Dirección
              </div>
              <b-form-input size="sm"  v-model.trim="$v.formData.address.$model" :state="$v.formData.address.$dirty ? !$v.formData.address.$error : null"></b-form-input>
              <b-form-invalid-feedback>
                Campo requerido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div v-if="formType == 'reservation' && optionalFields[formType].city.set" class="col-lg-6 mb-3">
            <b-form-group>
              <div class="text-left px-1 small text-dark form-label">
                Ciudad
              </div>
              <b-form-input size="sm"  v-model.trim="$v.formData.city.$model" :state="$v.formData.city.$dirty ? !$v.formData.city.$error : null"></b-form-input>
              <b-form-invalid-feedback>
                Campo requerido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <b-row class="mt-2">
          <div :class="`mt-2 mb-4 ${formType == 'reservation' ? 'col-12' : 'col-lg-6 col-12'}`">
            <div v-if="optionalFields[formType].receiveNews.set" class="custom-control custom-switch">
              <input type="checkbox" v-model="formData.receiveNews" class="custom-control-input" id="__BVID__249">
              <label class="custom-control-label" for="__BVID__249">
                <small>Deseo recibir información de {{customer}}</small>
              </label>
            </div>
          </div>
          <b-col v-if="formType == 'reservation'" cols="12" md="6">
            <b-form-group>
              <a href="javascript:;" class="pr-2" v-b-popover.hover="popoverConfig">Términos y condiciones</a>
              <b-form-checkbox v-model.trim="$v.formData.terms.$model" :state="$v.formData.terms.$dirty ? !$v.formData.terms.$error : null" class="d-inline-block" :value="true" :unchecked-value="null">
              </b-form-checkbox>
              <b-form-invalid-feedback :state="$v.formData.terms.$dirty ? !$v.formData.terms.$error : null">Debes aceptar los términos y condiciones.</b-form-invalid-feedback>
            </b-form-group>
            <p class="small text-muted mt-2">
              Para finalizar tu compra, debes declarar estar <br /> de acuerdo con los términos de tu compra.
            </p>
          </b-col>
          <div :class="{'text-center col-12': true, 'col-lg-6': !['cuadrado'].includes(getTemplate)}">
            <b-col cols="12" v-if="getIsButtonDisabled">
              <div v-if="!subAssetsGlobalMinNumber">
                <p style="color: red;" v-if="!getIsMinWarehouseNumberFulfilled && subAssetsRequirements.warehouse.required">
                  Debe seleccionar al menos {{getWarehousesMinNumber}} bodega(s)
                </p>
                <p style="color: red;" v-else-if="!getIsMinParkingLotsNumberFulfilled && subAssetsRequirements.parkingLot.required">
                  Debe seleccionar al menos {{getParkingLotsMinNumber}} estacionamiento(s)
                </p>
              </div>
              <p v-else style="color: red;">
                Debe seleccionar al menos {{subAssetsGlobalMinNumber}} bienes complementarios con esta oportunidad.
              </p>
            </b-col>
            <b-button @click="submitForm" :disabled="getIsButtonDisabled && (subAssetsRequirements.parkingLot.required || subAssetsRequirements.warehouse.required)" :style="`${getIsButtonDisabled ? 'background-color: rgba(239, 239, 239, 0.3); color: rgba(16, 16, 16, 0.3); border-color: rgba(118, 118, 118, 0.3); height: 50px !important;' : 'color: white; height: 50px !important; background-color:' + themeColors.primaryColor + '; border-color: ' +  themeColors.primaryColor}`" class="w-100 py-3 btn-block submit-button" size="sm">
              <h5 v-if="['cuadrado'].includes(getTemplate)">SOLICITAR {{ formType == 'quote' ? 'COTIZACIÓN' : 'RESERVA' }}</h5>
              <h5 v-else>{{ formType == 'quote' ? getSlang.quoteSlang.quoteAction.toUpperCase() : 'RESERVAR' }}</h5>
            </b-button>
          </div>
        </b-row>
      </form>
    </b-row>
  </b-container>
</template>

<script>
import ModalDataDefault from '../components/ModalDataDefault.vue'
import ModalDataCuadrado from '../components/ModalDataCuadrado.vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import {
  validate as isValidRUT,
  format as formatRUT,
  clean as cleanRUT,
} from 'rut.js'

const customValidateRUT = (value) => {
  if (!isValidRUT(value)) return false
  const RUTCleaned = cleanRUT(value)
  let RUTLength = RUTCleaned.length
  if (RUTCleaned.includes('K')) RUTLength -= 1
  return RUTLength >= 6 && RUTLength <= 9
}

export default {
  name: 'QuoteForm',
  props: {
    formType: { type: String, default: 'quote' },
  },
  components: {
    ModalDataDefault,
    ModalDataCuadrado
  },
  data(){
    return{
      currency: 'UF',
      formData: {
        fName: null,
        lName: null,
        email: null,
        phone: null,
        terms: null,
        commune: null,
        ageRange: null,
        profession: null,
        destinationPurchase: null,
        sex: null,
        rentRange: null,
        rut: null,
        receiveNews: true,
        address: null,
        city: null,
      },
      phoneValidationStatus: null,
      bookingData: {
        currency: 'UF',
        value: 0,
      },
      images: {
        projectImage: null,
        assetImage: null,
      },
      subAssetsGlobalMaxNumber: null,
      subAssetsGlobalMinNumber: null,
    }
  },
  created() {
    this.setProjectId(this.getProjectData.id)
    this.setMaxAndMins()
    this.fetchAssetMaps()
    this.fetchAssetsData()
    this.setAddedAssets([])
    this.setAddedParkingLots([])
    this.setAddedWarehouses([])
    this.setIsButtonDisabled(false)
    this.bookingData.currency = this.selectedOpportunity.opportunityDataProjectJSON.montoReservaJSON.tipoCalculo == 'PORCENTAJE' ||
    this.selectedOpportunity.opportunityDataProjectJSON.montoReservaJSON.tipoCalculo == 'VALOR_AJUSTE' ? 'UF' : 'CLP'
    this.bookingData.value = this.selectedOpportunity.opportunityDataProjectJSON.montoReservaJSON.montoReserva
    this.setloadingPaymentPlan(true)
    this.fetchPaymentPlan()
    this.setAssociatedLimits()
  },
  computed: {
    ...mapGetters(
      'general', [
      'getProjectData',
      'getSlang',
      'getUfValue',
      'getIsMinParkingLotsNumberFulfilled',
      'getIsMinWarehouseNumberFulfilled',
      'getWarehousesMinNumber',
      'getParkingLotsMinNumber',
      'getTemplate'
    ]),
    ...mapGetters('opportunities', ['getImageURL', 'getOpportunitiesSubAssets', 'getAssetInfo', 'getOpportunityAssetsValidation', 'getBAssociatedGroup']),
    ...mapState('opportunities', ['selectedOpportunity', 'propertyNames']),
    ...mapState('general', ['customer', 'themeColors', 'countryCode', 'paymentData', 'loadingPaymentPlan', 'version']),
    ...mapState('form', [
      'fetchedComunas',
      'fetchedAges',
      'fetchedProfessions',
      'fetchedPurchaseDestinations',
      'fetchedSexs',
      'fetchedRentRanges',
      'optionalFields',
      'subAssetsRequirements']),
    ...mapGetters('assets', ['getAddedAssets','getAddedParkingLots','getAddedWarehouses']),
    ...mapGetters('form', ['getIsButtonDisabled',]),
    assetName(){
      return `${this.selectedOpportunity.tipoBien} ${this.selectedOpportunity.numeroDeBien}`
    },
    cssVars() {
      return {
        '--primary-color': this.themeColors.primaryColor,
        '--secondary-color': this.themeColors.secondaryColor,
      }
    },
    popoverConfig() {
      return {
        html: true,
        container: 'mobysuite-opportunities-modal',
        title: () => {
          return 'CONDICIONES DE RESERVA ONLINE'
        },
        content: () => {
          return '<table><tr><td>Dentro del plazo de 10 días hábiles me comprometo a suscribir la correspondiente promesa de compraventa. El no cumplimiento, será considerado DESISTIMIENTO. En este caso la Inmobiliaria no reembolsará el monto por concepto de reserva y podrá disponer de la propiedad para la venta. </td></tr><tr><td>El pie de la vivienda debe estar cancelado al momento de firmar la escritura.</td></tr><tr><td> <strong>Gastos operacionales:</strong></td></tr><tr><td> Se cancelarán a la institución que otorga el crédito y el monto será determinado por la institución crediticia. </td></tr><tr><td>En caso de pago contado, se cancelan a la Inmobiliaria. Los gastos operacionales NO están incluidos en el Precio.</td></tr>'
        }
      }
    },
    notSelectableAssets() {
      let assets = []
      assets = assets.concat(this.getBAssociatedGroup.map( asset => asset.id))
      assets = assets.concat(this.getOpportunitiesSubAssets.map( asset => asset.id))
      return assets
    },
    discountInfo(){
        return this.selectedOpportunity.descuentoGrupo.find(element => element["tipoDescuento"] == "GRUPO_BIENES")
      },
  },
  methods: {
    ...mapActions('assets', ['fetchAssetsData']),
    ...mapActions('general', ['fetchAssetMaps', 'fetchPaymentPlan']),
    ...mapMutations('general', [
      'setProjectId',
      'setMaxAndMins',
      'setIsMinWarehouseNumberFulfilled',
      'setIsMinParkingLotsNumberFulfilled',
      'setloadingPaymentPlan',
    ]),
    ...mapMutations('assets', [
      'setAddedAssets',
      'setAddedParkingLots',
      'setAddedWarehouses',
    ]),
    ...mapMutations('form', ['setIsButtonDisabled','setQuoteStatus', 'setOptionalFields']),
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('homepage')

      const self = this
      const version = this.version == 2 ? 'v3' : 'v2'
      this.axios
        .post(`${version}/quotes/validate-captcha-token`, {
          token: token,
        })
        .then(response => {
          if (response.data.valid) {
            let data = self.formData
            if(!this.optionalFields[this.formType].receiveNews.set)
              delete data["receiveNews"]
            if (self.formType == 'quote')
            {
              //Clean object
              delete data["terms"]
              delete data["address"]
              delete data["apartment"]
              delete data["city"]
              self.$emit('close-modal')
              self.sendForm(data)
            }
            else {
              //Clean object
              // delete reservation["rentRange"]
              // delete reservation["profession"]
              // delete reservation["ageRange"]
              // delete reservation["destinationPurchase"]
              // delete reservation["sex"]
              // //must rename some fields accordig to service
              // delete reservation["fName"]
              // delete reservation["lName"]
              // delete reservation["phone"]
              // reservation = {
              //   ... reservation,
              //   ...{
              //     name: self.formData.fName,
              //     lastname: self.formData.lName,
              //     cellphone: self.formData.phone
              //   }
              // }
              self.$emit('close-modal')
              self.reservationToken(data)
            }
          } else {
            self.setDataError()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    setCurrency(type){
      this.currency = type
    },
    setAssetPrice(price, format = true){
      let amount = format ? this.currency == 'UF' ? price : price*this.getUfValue : price
      let formattedPrice = new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format((this.currency == 'CLP' ? Math.round(amount) : amount))
      return `${this.currency == 'CLP' ? '$' : ''} ${formattedPrice} ${this.currency == 'UF' ? 'UF' : ''}`
    },
    setTotal(format){
      let allAssets = this.getAddedAssets.concat(this.getBAssociatedGroup)
      allAssets.push(this.selectedOpportunity)
      allAssets = allAssets.concat(this.getOpportunitiesSubAssets)
      allAssets = allAssets.map( (asset) => parseFloat(asset.bienMaestroDescuento ? asset.bienMaestroDescuento : asset.valorLista))
      const total = allAssets.reduce( (previous, current) => previous + current, 0 )
      return format ? this.setAssetPrice(total) : this.currency == 'UF' ? total : total*this.getUfValue
    },
    ...mapActions('form', ['sendForm', 'reservationToken']),
    async submitForm() {
      await this.validateMins()
      this.$v.$touch()
      if (!this.$v.$invalid && !this.getIsButtonDisabled) {
          this.setQuoteStatus(true)
          this.recaptcha()
      }
    },
    rutFormat(){
      this.formData.rut = formatRUT(this.formData.rut)
    },
    validateMins(){
      if(((this.getAddedWarehouses.length >= this.getWarehousesMinNumber ||
        this.getWarehousesMinNumber === null) && this.subAssetsGlobalMinNumber == null) || (this.subAssetsGlobalMinNumber != null && (this.getAddedAssets.length >= this.subAssetsGlobalMinNumber)))
        {
          this.setIsMinWarehouseNumberFulfilled(true)
          this.setIsButtonDisabled(false)
        }
      else
      {
        this.setIsMinWarehouseNumberFulfilled(false)
        this.setIsButtonDisabled(true)
      }

      if(((this.getAddedParkingLots.length >= this.getParkingLotsMinNumber ||
        this.getParkingLotsMinNumber === null) && this.subAssetsGlobalMinNumber == null) || (this.subAssetsGlobalMinNumber != null && (this.getAddedAssets.length >= this.subAssetsGlobalMinNumber)))
        {
          this.setIsMinParkingLotsNumberFulfilled(true)
          this.setIsButtonDisabled(false)
        }
      else
        {
          this.setIsMinParkingLotsNumberFulfilled(false)
          this.setIsButtonDisabled(true)
        }
    },
    phoneCustomValidation(number, phoneObject){
      this.formData.phone = phoneObject.number
      this.phoneValidationStatus = phoneObject.valid ? true : false
      return phoneObject.valid ? true : false
    },
    bookingAmount(format = false){
      let amount = 0
      if(this.paymentData.status == 'success'){
        amount = this.currency == 'UF' ? this.paymentData.adjustmentValue : this.paymentData.localCurrencyValue
      }

      if (this.currency == 'UF' &&  this.bookingData.currency == 'CLP') { amount = this.bookingData.value / this.getUfValue }
      else if (this.currency == 'CLP' &&  this.bookingData.currency == 'UF') { amount = this.bookingData.value * this.getUfValue }
      else { amount = this.bookingData.value }

      if(format){
        if(this.currency == 'CLP'){
          return `$${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(Math.round(amount))}`
        }else{
          return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(amount)} UF`
        }
      }

      return amount
    },
    setAssociatedLimits(){
      if(this.selectedOpportunity[this.propertyNames.associatedLimits.max]){
        this.subAssetsGlobalMaxNumber = this.selectedOpportunity[this.propertyNames.associatedLimits.max]
        this.subAssetsGlobalMinNumber = this.selectedOpportunity[this.propertyNames.associatedLimits.min]
      } else{
        this.subAssetsGlobalMaxNumber = null
        this.subAssetsGlobalMinNumber = null
      }
    }
  },
  validations: {
    formData: {
      fName: { required },
      lName: { required },
      email: { required, email },
      terms:  { required: requiredIf(function () {
        return this.formType == 'reservation'
      })},
      commune:{ required: requiredIf(function () {
        return this.optionalFields[this.formType ? this.formType : 'quote']["commune"]["required"]
      })},
      ageRange: { required: requiredIf(function () {
        return this.optionalFields[this.formType ? this.formType : 'quote']["ageRange"]["required"]
      })},
      profession: { required: requiredIf(function () {
        return this.optionalFields[this.formType ? this.formType : 'quote']["profession"]["required"]
      })},
      destinationPurchase: { required: requiredIf(function () {
        return this.optionalFields[this.formType ? this.formType : 'quote']["destinationPurchase"]["required"]
      })},
      sex: { required: requiredIf(function () {
        return this.optionalFields[this.formType ? this.formType : 'quote']["sex"]["required"]
      })},
      rentRange: { required: requiredIf(function () {
        return this.optionalFields[this.formType ? this.formType : 'quote']["rentRange"]["required"]
      })},
      rut: { customValidateRUT },
      phone:{ required, function () {
        return this.phoneCustomValidation
      }},
      address: { required: requiredIf(function () {
        return this.formType == 'reservation' && this.optionalFields[this.formType ? this.formType : 'quote']["direction"]["required"]
      })},
      city: { required: requiredIf(function () {
        return this.formType == 'reservation' && this.optionalFields[this.formType ? this.formType : 'quote']["city"]["required"]
      })},
    }
  },
  watch: {
    formType(newVal){
      if(newVal){
        this.setOptionalFields({project: this.getProjectData, formType: newVal})
      }
    }
  }
}
</script>
<style scoped lang="scss">
  mobysuite-opportunities {
.btn-outline-customer.active, .btn-outline-customer:hover {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-outline-customer {
  color: var(--primary-color) !important;
  background-color: transparent !important;
  border-color: var(--primary-color) !important;
}
.btn-customer.active, .btn-customer:hover {
  color: var(--primary-color) !important;
  background-color: #fff !important;
  border-color: var(--primary-color) !important;
}
.btn-customer {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.primary-table-header{
  background-color: var(--primary-color) !important;
}
.focus-color{
  background-color: var(--secondary-color) !important;
}
.vue-tel-input__error-message {
  color: red;
}
.vue-tel-input--error {
  border-color: red;
  box-shadow: 0 0 0 0.2rem rgb(235 87 87 / 25%) !important;
}
.vue-tel-input--success{
  border-color: #28a745;
}
    .opportunities-img{
      max-height: 270px;
    }
    .bg-customer{
        font-size: 0.9em;
        background-color: green;
        min-width: 75px;
        padding: 5px 25px;
        text-align: center;
        vertical-align: middle;
        border-radius: 42px;
    }
    .discount{
        margin-left: 10px;
    }
    .bordered-table{
        border-right: 1px solid #333;
    }
    .discount-number{
        font-size: 15px;
    }
    .btn-quote{
        color: var(--secondary-color) !important;
        background-color: transparent !important;
        border-color: var(--secondary-color) !important;
    }
    .btn-quote:hover{
        color: #fff !important;
        background-color: var(--secondary-color) !important;
        border-color: var(--secondary-color) !important;
    }
    .btn-reservation {
        color: #fff !important;
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }
    .image{
        position:relative;
        //overflow:hidden;
        //padding-bottom:100%;
    }
    .image img{
        //position:absolute;
    }
  }
</style>
