<template>
    <b-col
        :lg="first ? '12' : '3'"
        :md="first ? '12' : '4'"
        sm="12"
        class="my-2"
    >
      <b-row v-if="first" class="first-card p-4 text-primary-color" no-gutters>
        <b-col cols="6">
          <div style="padding: 15px;">
            <div class="h3 font-weight-bold mb-3 text-uppercase">{{ projectInfo.nombreProyecto }} </div>
            <div class="h4 mb-4 text-uppercase">{{ assetType }} {{opportunityNumber ? opportunityNumber : assetTypology}} </div>
            <div v-if="false" class="h5 font-weight-bold mb-2">{{ roomsAmount }} dorm + {{bathroomsAmount}} baño{{bathroomsAmount > 1 ? 's' : '' }}</div>
            <div class="h5 font-weight-bold mb-2">{{ discountInfo.descripcion }}</div>
            <div class="h5 mb-2">Sup. total {{ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(totalSize)}} m2 aprox.</div>
            <div class="h5 mb-4">Sup. terraza {{ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(terraceSize)}} m2 aprox.</div>
            <b-row>
                <b-col xl="12">
                    <Price
                        :first="first"
                        :discountInfo="discountInfo"
                        :normalPrice="normalPrice"
                        :internetPrice="internetPrice"
                        :booking="projectInfo.montoReservaJSON"
                        :discount-value="setDiscountValue()"
                        :discount-text="setDiscountText()"
                        style="margin-bottom: 20px;"
                    />
                </b-col>
            </b-row>
          </div>
          <b-row class="footer" style="margin-top: auto;">
              <b-col :cols="`${ projectInfo.activoReservaWeb ? '6' : '12' }`">
                  <b-button variant="quote" size="sm" :style="cssVars" class="w-100 py-5 btn-action" :class="{'alone': !projectInfo.activoReservaWeb, 'left': projectInfo.activoReservaWeb}" @click="setSelectedOpportunity(assetId), resetSecondaryAssetsToQuote(), $emit('button-action', 'quote')" v-if="projectInfo.activoCotizadorWeb && useQuoteService">
                      {{ getSlang.quoteSlang.quoteAction }}
                  </b-button>
              </b-col>
              <b-col :cols="`${ useQuoteService ? '6' : '12' }`" >
                  <b-button variant="reservation" size="sm" :style="cssVars" :class="{'alone': !useQuoteService, 'right': useQuoteService}" class="w-100 py-5 btn-action" @click="setSelectedOpportunity(assetId), resetSecondaryAssetsToQuote(), $emit('button-action', 'reservation')" v-if="projectInfo.activoReservaWeb">
                      Reserva
                  </b-button>
              </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" >
          <img v-if="useProjectImage" :src="getProjectData.urlImagenUnoProyectoCompleta" @error="errorImage" class="w-100" />
          <img v-else :src="imageSrc" @error="errorImage" class="w-100" />
        </b-col>
      </b-row>
      <div v-else class="card">
        <div class="image-container" >
          <div class="w-100">
            <div v-if="useProjectImage" :class="{'project-image': ['cuadrado'].includes(getTemplate)}" >
              <img :src="getProjectData.urlImagenUnoProyectoCompleta" @error="errorImage" :class="{'image-size': !['cuadrado'].includes(getTemplate)}" />
            </div>
            <img v-else :src="imageSrc" @error="errorImage" :class="{'image-size': true}" />
            </div>
            <template v-if="discountInfo">
              <div class="discount text-center">
                  <div class=" pt-2 text-white bg-customer text-center" :style="{backgroundColor: setColor(discountInfo.color)}">
                      <span class="discount-number font-weight-bold">{{setDiscountValue()}}{{setDiscountText()}} OFF</span>
                  </div>
              </div>
          </template>
        </div>
        <div style="padding: 15px;">
          <table v-if="!['cuadrado'].includes(getTemplate)" class="w-100 text-center my-4">
            <tr>
              <td class="bordered-table"><font-awesome-icon :icon="['fal', 'bed-front']" /> {{roomsAmount}}</td>
              <td class="bordered-table"><font-awesome-icon :icon="['fal', 'bath']" /> {{bathroomsAmount}}</td>
              <td class="bordered-table"><font-awesome-icon :icon="['fal', 'ruler-combined']" /> {{ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(assetSize)}}</td>
              <td><font-awesome-icon :icon="['fal', 'door-open']" /> {{opportunityNumber ? opportunityNumber : assetTypology}}</td>
            </tr>
          </table>
          <div style="color: #222 !important;" class="font-weight-bold mt-4 mb-1">{{ projectInfo.nombreProyecto }} </div>
          <h5 style="color: #222 !important;" v-if="discountInfo">{{ discountInfo.descripcion }} </h5>
          <b-row>
              <b-col xl="12">
                  <Price
                      :discountInfo="discountInfo"
                      :normalPrice="normalPrice"
                      :internetPrice="internetPrice"
                      :booking="projectInfo.montoReservaJSON"
                      style="margin-bottom: 20px;"
                  />
              </b-col>
          </b-row>
        </div>
        <b-row class="footer" style="margin-top: auto;">
            <b-col :cols="`${ projectInfo.activoReservaWeb ? '6' : '12' }`">
                <b-button variant="quote" size="sm" class="w-100 py-5 btn-action" :class="{'alone': !projectInfo.activoReservaWeb, 'left': projectInfo.activoReservaWeb}" @click="setSelectedOpportunity(assetId), resetSecondaryAssetsToQuote(), $emit('button-action', 'quote')" v-if="projectInfo.activoCotizadorWeb && useQuoteService">
                    {{ getSlang.quoteSlang.quoteAction }}
                </b-button>
            </b-col>
            <b-col :cols="`${ useQuoteService ? '6' : '12' }`" >
                <b-button variant="reservation" size="sm" :class="{'alone': !useQuoteService, 'right': useQuoteService}" class="w-100 py-5 btn-action" @click="setSelectedOpportunity(assetId), resetSecondaryAssetsToQuote(), $emit('button-action', 'reservation')" v-if="projectInfo.activoReservaWeb">
                    Reserva
                </b-button>
            </b-col>
        </b-row>
      </div>
    </b-col>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import Price from '../components/Price.vue'

export default {
    name: 'Card',
    components: {
        Price,
    },
    data() {
      return {
        imageSrc: null,
      }
    },
    props: {
        first:{
          type: Boolean,
          default: false,
        },
        imageURL:{
            type: String,
            default: null,
        },
        discountInfo: {
            type: Object,
            default: null,
        },
        projectId: {
            type: Number,
            default: null,
        },
        projectInfo: {
            type: Object,
            default: null,
        },
        opportunityNumber: {
            type: String,
            default: null,
        },
        assetId: {
            type: Number,
            default: null,
        },
        assetSize: {
            type: Number,
            default: null,
        },
        totalSize: {
            type: Number,
            default: null,
        },
        terraceSize: {
            type: Number,
            default: null,
        },
        assetType: {
            type: String,
            default: null,
        },
        assetTypology: {
            type: String,
            default: null,
        },
        roomsAmount: {
            type: Number,
            default: null,
        },
        bathroomsAmount: {
            type: Number,
            default: null,
        },
        normalPrice: {
            type: Number,
            default: null,
        },
        internetPrice: {
            type: Number,
            default: null,
        },
        bookingAmout: {
            type: Number,
            default: null,
        },
        authorizedDiscount: {
            type: Number,
            default: null,
        },
        notAuthorizedDiscount: {
            type: Number,
            default: null,
        },
    },
    mounted(){
      this.imageSrc = this.getImageURL(this.assetId)
    },
    methods: {
        ...mapMutations('opportunities', ['setSelectedOpportunity']),
        ...mapMutations('assets', ['resetSecondaryAssetsToQuote']),
        setDiscountText() {
            if(this.discountInfo.tipoMonto=='PORCENTAJE' || this.discountInfo.tipoMonto=='MONTO_DESCUENTO_BIEN' || this.discountInfo.tipoMonto=='MONTO_DESCUENTO_NO_AUTORIZADO_BIEN')
                return ' %'
            else if (this.discountInfo.tipoMonto=='CONSTANTE')
                return ' UF'
            else if (this.discountInfo.tipoMonto=='MONTO')
                return ' CLP'
        },
        setDiscountValue() {
            if(this.discountInfo.tipoMonto=='PORCENTAJE')
                return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.discountInfo.montoDescuento * 100)}`
            else if (this.discountInfo.tipoMonto=='CONSTANTE')
                return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.discountInfo.montoDescuento)}`
            else if (this.discountInfo.tipoMonto=='MONTO')
                return `$${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.discountInfo.montoDescuento)}`
            else if (this.discountInfo.tipoMonto=='MONTO_DESCUENTO_BIEN')
                return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.authorizedDiscount * 100)}`
            else if (this.discountInfo.tipoMonto=='MONTO_DESCUENTO_NO_AUTORIZADO_BIEN')
                return `${new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(this.notAuthorizedDiscount * 100)}`
        },
        modalVisibility(){
          this.setSelectedOpportunity(this.assetId);
          this.resetSecondaryAssetsToQuote()
          if(this.projectInfo.activoCotizadorWeb && this.useQuoteService){
            this.$emit('button-action', 'quote')
          }else if(this.projectInfo.activoReservaWeb){
            this.$emit('button-action', 'reservation')
          }
        },
        setColor(color){
          switch(color){
            case 'Verde':
              return '#008000';
            case 'Rojo':
              return '#E60000';
            case 'Azul':
              return '#1a5a80';
            default:
              return '#008000';
          }
        },
        errorImage(){
          this.imageSrc = "https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png"
        },
    },
    computed: {
        ...mapGetters('opportunities', ['getImageURL','getAssetById']),
        ...mapGetters('general', ['getSlang', 'getProjects', 'getTemplate']),
        ...mapState('general', ['themeColors', 'useQuoteService', 'useProjectImage', 'propertyNames']),
        cssVars() {
            let cssVars = {
              '--primary-color': this.themeColors.primaryColor,
              '--secondary-color': this.themeColors.secondaryColor
            }
            return cssVars
        },
      getProjectData() {
        const project = this.getProjects.find(project => project[this.propertyNames.id] == this.projectId)
        const projectImage = project[this.propertyNames.projectImage]
        ? project[this.propertyNames.projectImage]
        : null
        return {...project, ...{image: projectImage}}
    },
    },
}
</script>

