export default {
	formData: {
		dniName: 'DNI',
		quoteName: 'Proforma',
		reservationName: 'Separación',
	},
	quoteSlang: {
		quoteAction: 'Proformar',
		quotedProducts: 'Productos proformados',
	},
	tooltips: {
		DNI: '8 dígitos',
	},
}
