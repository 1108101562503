import Vue from 'vue'

const state = {
    fetchedComunas: [],
    fetchedAges: [],
    fetchedProfessions: [],
    fetchedPurchaseDestinations: [],
    fetchedSexs: [],
    fetchedRentRanges: [],
    customObservationsQuestion: 'Observaciones',
    fieldValues: {
        rut: null,
        fName: null,
        lName: null,
        email: null,
        phone: null,
        phoneObject: null,
        ageRange: null,
        destinationPurchase: null,
        comuna: null,
        profesion: null,
        sex: null,
        rentRange: null,
        observation: null,
    },
    sendingQuote: false,
    captchaError: false,
    isButtonDisabled: false,
    quoteNotification: {
      success: true,
      title: 'Notification dialog title',
      body: 'Notification dialog body',
      pdfURL: 'url',
      reservationURL: '',
      reservationCode: '',
      id: 0,
      name: 'customer name',
      quotedAssets: [],
      total: 0,
    },
    formSubmissionResponded: false,
    errorMessage: null,
    errorMessageTitle: null,
    showError: false,
    propertyNames: {
      requiredFields: 'camposRequeridosProductos',
      requiredFieldsProperties: {
        name: 'nombre',
        included: 'incluido',
        required: 'obligatorio',
        product: 'producto',
      },
      parkingLot: 'Estacionamiento',
      warehouse: 'Bodega',
      floor: 'Piso',
      ageRange: 'Rango edad',
      destinationPurchase: 'Destino compra',
      commune: 'Comuna',
      profession: 'Profesion',
      sex: 'Sexo',
      rentRange: 'Rango renta',
    },
    optionalFields: {
      reservation: {
        ageRange: { set: false, required: false, serviceName: 'Rango edad' },
        destinationPurchase: {
          set: false,
          required: false,
          serviceName: 'Destino compra',
        },
        commune: { set: false, required: false, serviceName: 'Comuna' },
        profession: { set: false, required: false, serviceName: 'Profesion' },
        sex: { set: false, required: false, serviceName: 'Sexo' },
        rentRange: { set: false, required: false, serviceName: 'Rango renta' },
        direction: { set: false, required: false, serviceName: 'Direccion' },
        city: { set: false, required: false, serviceName: 'Ciudad' },
        receiveNews: { set: false, required: false, serviceName: 'Recibir noticias' },
      },
      quote: {
          ageRange: { set: false, required: false, serviceName: 'Rango edad' },
          destinationPurchase: {
            set: false,
            required: false,
            serviceName: 'Destino compra',
          },
          commune: { set: false, required: false, serviceName: 'Comuna' },
          profession: { set: false, required: false, serviceName: 'Profesion' },
          sex: { set: false, required: false, serviceName: 'Sexo' },
          rentRange: { set: false, required: false, serviceName: 'Rango renta' },
          receiveNews: { set: false, required: false, serviceName: 'Recibir noticias' },
      }
    },
    subAssetsRequirements:{
      warehouse: {
        set: false,
        required: false,
      },
      parkingLot: {
        set: false,
        required: false,
      }
    }
}

const mutations = {
    setCustomObservationsQuestion: (state, payload) => {
        state.customObservationsQuestion = payload
    },
    setFieldValue: (state, { fieldName, value, phoneObject = null }) => {
        state.fieldValues[fieldName] = value

        if (fieldName == 'phone') state.fieldValues.phoneObject = phoneObject
    },
    setComunas: (state, payload) => (state.fetchedComunas = payload),
    setAges: (state, payload) => (state.fetchedAges = payload),
    setProfessions: (state, payload) => (state.fetchedProfessions = payload),
    setPurchaseDestinations: (state, payload) =>
        (state.fetchedPurchaseDestinations = payload),
    setSexs: (state, payload) => (state.fetchedSexs = payload),
    setRentRanges: (state, payload) => (state.fetchedRentRanges = payload),
    setQuoteStatus: (state, payload) => (state.sendingQuote = payload),
    setCaptchaError: (state, payload) => (state.captchaError = payload),
    setIsButtonDisabled: (state, payload) => (state.isButtonDisabled = payload),
    setQuoteData: (state, payload) => (state.quoteNotification = payload),
    setFormSubmissionResponded: (state, value) =>
    (state.formSubmissionResponded = value),
    setErrorMessage: (state, payload) => state.errorMessage = payload,
    setErrorMessageTitle: (state, payload) => state.errorMessageTitle = payload,
    setErrorStatus: (state, payload) => state.showError = payload,
    setOptionalFields: (state, data) => {
      const optionalFields = data.project[
        state.propertyNames.requiredFields
        ].filter(
          property =>
            property[state.propertyNames.requiredFieldsProperties.product] ===
            (data.formType == 'quote' ? 'OPORTUNIDADES COTIZACION WEB' : 'OPORTUNIDADES RESERVA WEB')
      )
			// CHANGE REQUESTED BY PAMELA AGUIRRE 16/06/2022. PLEASE CHANGE IT TO COMMENTED FUNCTIONALITY ASAP
      // const wareHouseServiceData = optionalFields.find(
      //   serviceField => serviceField['nombre'] == 'Bodega'
      // )
      // const parkingLotServiceData = optionalFields.find(
      //   serviceField => serviceField['nombre'] == 'Estacionamiento'
      // )

      // state.subAssetsRequirements.parkingLot.set = parkingLotServiceData ? parkingLotServiceData[state.propertyNames.requiredFieldsProperties.included] : false
      // state.subAssetsRequirements.parkingLot.required = parkingLotServiceData ? parkingLotServiceData[state.propertyNames.requiredFieldsProperties.required] : false
      // state.subAssetsRequirements.warehouse.set = wareHouseServiceData ? wareHouseServiceData[state.propertyNames.requiredFieldsProperties.included] : false
      // state.subAssetsRequirements.warehouse.required = wareHouseServiceData ? wareHouseServiceData[state.propertyNames.requiredFieldsProperties.required] : false
      state.subAssetsRequirements.parkingLot.set = data.project['estacionamientosCotizadorWeb']
      state.subAssetsRequirements.parkingLot.required = data.project['estacionamientosCotizadorWebObligatorio']
      state.subAssetsRequirements.warehouse.set = data.project['bodegasCotizadorWeb']
      state.subAssetsRequirements.warehouse.required = data.project['bodegasCotizadorWebObligatorio']

      // Set fields config comming from the service (render and required).

      for (let [key, field] of Object.entries(
        state.optionalFields[data.formType]
      )) {
        const fieldData = optionalFields.find(
          serviceField => serviceField['nombre'] == field['serviceName']
        )
        if (fieldData) {
          state.optionalFields[data.formType][key].set = fieldData[state.propertyNames.requiredFieldsProperties.included]
          state.optionalFields[data.formType][key].required = fieldData[state.propertyNames.requiredFieldsProperties.required]
        }
          }

    },
}

const actions = {
    sendForm: ({ commit, rootState, rootGetters }, inputs) => {
      const version = rootState.general.version == 2 ? 'v3' : 'v2'
        Vue.axios
            .post(`${version}/quotes`, {
                real_estate: rootState.general.customer,
                project_id: rootState.opportunities.selectedOpportunity.proyecto,
                utm_campaign: rootState.general.utmData.utmCampaign,
                utm_content: rootState.general.utmData.utmContent,
                utm_medium: rootState.general.utmData.utmMedium,
                utm_source: rootState.general.utmData.utmSource,
                utm_term: rootState.general.utmData.utmTerm,
                assets: rootGetters['assets/getSelectedAssetsId'],
                discountId: rootState.general.useOpportunityDiscount ?
                rootGetters['opportunities/getOpportunityDiscount']['id'] : null,
                ...inputs,
            })
            .then(response => {
                const payload = {
                    success: true,
                    title: 'EXITO',
                    body: `Cotización realizada con éxito`,
                    reservationURL: '',
                    reservationCode: '',
                    pdfURL: response.data.cotizacion.urlDocumento,
                    id: response.data.cotizacion.id,
                    name: inputs.fName,
                    quotedAssets: response.data.cotizacion.bienes,
                    total: response.data.cotizacion.totalCotizacion,
                  }
                commit('setQuoteData', payload)
                commit('setFormSubmissionResponded', true)
                state.sendingQuote = false
            })
            .catch(error => {
                const payload = {
                    success: false,
                    title: 'ERROR',
                    body: `Ocurrió un fallo al realizar la cotización} `,
                    pdfURL: '',
                    reservationURL: '',
                    reservationCode: '',
                    id: '',
                    name: '',
                    quotedAssets: [],
                    total: 0,
                }

                commit('setQuoteData', payload)
                commit('setFormSubmissionResponded', true)
                commit('setErrorMessageTitle', 'Tenemos un problema')
                commit('setErrorMessage', 'Ocurrió un error al realizar la cotización, por favor inténtalo en unos minutos nuevamente.')
                commit('setErrorStatus', true)
                state.sendingQuote = false
                console.log(error)
            })
    },
    fetchDemographicData: (
        { commit, rootState },
        { table, mutationName }
    ) => {
        const version = rootState.general.version == 2 ? 'v3' : 'v2'
        Vue.axios
            .get(`${version}/quotes/list-demographic-info`, {
                params: {
                    real_estate: rootState.general.customer,
                    table,
                },
            })
            .then(response => commit(mutationName, response.data))
            .catch(error => {
                console.log(error)
            })
    },
    fetchDemographicAllData: ({ dispatch }) => {
        const communeData = {
            table: 'COMUNA',
            mutationName: 'setComunas',
        }

        const ageRangedata = {
            table: 'RANGO_EDAD',
            mutationName: 'setAges',
        }

        const professionData = {
            table: 'PROFESION',
            mutationName: 'setProfessions',
        }

        const destinationPurchaseData = {
            table: 'DESTINO_COMPRA',
            mutationName: 'setPurchaseDestinations',
        }

        const sexData = {
            table: 'SEXO',
            mutationName: 'setSexs',
        }

        const rentRangeData = {
            table: 'RANGO_RENTA',
            mutationName: 'setRentRanges',
        }

        const demographicData = [
            communeData,
            ageRangedata,
            professionData,
            destinationPurchaseData,
            sexData,
            rentRangeData,
        ]

        demographicData.forEach(item => {
            dispatch('fetchDemographicData', item)
        })
    },
  reservationToken({ rootState, rootGetters, commit }, reservation) {
          const version = rootState.general.version == 2 ? 'v3' : 'v2'
          Vue.axios
            .post(`${version}/quotes/reservations`, {
                real_estate: rootState.general.customer,
                project_id: rootState.opportunities.selectedOpportunity.proyecto,
                utm_campaign: rootState.general.utmData.utmCampaign,
                utm_content: rootState.general.utmData.utmContent,
                utm_medium: rootState.general.utmData.utmMedium,
                utm_source: rootState.general.utmData.utmSource,
                utm_term: rootState.general.utmData.utmTerm,
                assets: rootGetters['assets/getSelectedAssetsId'],
                discountId: rootGetters['opportunities/getOpportunityDiscount']['id'],
                ...reservation,
            })
            .then(response => {
                console.log(response)
                var form = document.createElement('form')
                document.body.appendChild(form)
                form.method = 'post'
                form.action = response.data.url
                var input = document.createElement('input')
                input.type = 'hidden'
                input.id = 'webpay-token'
                input.name = 'token_ws'
                input.value = response.data.token
                form.appendChild(input)
                console.log(response.data)
                form.submit()
                document.body.removeChild(form)
            })
            .catch(error => {
              commit('setErrorMessageTitle', 'Tenemos un problema')
              commit('setErrorMessage', 'Ocurrió un error al realizar la reserva, por favor inténtalo en unos minutos nuevamente.')
              commit('setErrorStatus', true)
              commit('setFormSubmissionResponded', true)
              state.sendingQuote = false
              console.log(error)
            })
    },
}
const getters = {
    getIsButtonDisabled: state => state.isButtonDisabled,
    getErrorMessage: state => state.errorMessage,
    getErrorMessageTitle: state => state.errorMessageTitle,
    getQuoteData: state => state.quoteNotification,
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
