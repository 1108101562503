<template>
  <div class="mt-5 pt-5" style="min-width:100%; font-size: 25px;">
    <b-icon
      icon="clock"
      animation="cylon"
      font-scale="3"
      shift-v="0">
    </b-icon>
	<p>{{ message }}</p>
  </div>
</template>

<script>
export default {
	name: 'LoadingAnimation',
  props: {
    message: { type: String, default: '' },
  },
}
</script>
