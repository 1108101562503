<template>
  <b-row
    no-gutters
    style='min-height:100vh; background-color: #EEE'
    class="justify-content-md-center"
  >
    <div style='background-color: #EEE; display: flex; align-items: center;' class="px-4 py-4">
        <b-row style='background-color: white; padding-right: 70px; padding-bottom: 30px; padding-top:15px;' class="pl-4 pr-6 py-8 overflow-hidden" align-content="center">
          <b-col class="px-2" cols="12" sm="12" :md="getProjectData.image ? 6 : 12">
            <b-row no-gutters class="d-block">
              <p class="h3 mt-4 font-weight-bold">
                <b>
                  {{ getQuoteData.name.toUpperCase() }}
                </b>
              </p>
              <p class="h4 mt-4">
                <b>
                  GRACIAS POR
                  {{ getSlang.quoteSlang.quoteAction.toUpperCase() }}
                </b>
              </p>
              <p class="mt-1">
                UN EJECUTIVO TE CONTACTARÁ EN BREVE.
              </p>
              <br />
              <p
                class="h6 font-weight-bold"
                :style="`color: gray`"
              >
                RESUMEN DE {{ getSlang.formData.quoteName.toUpperCase() }} Nº
                {{
                  new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(getQuoteData.id)
                }}
              </p>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr :style="cssVars" class="primary-table-header text-white">
                    <th scope="col">Tipo de bien</th>
                    <th scope="col" class="text-right">Precio UF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asset, key) in getQuoteData.quotedAssets" v-bind:key="'asset'+key">
                      <td scope="row">{{`${asset.tipoBien} ${asset.numeroDeBien}`}}</td>
                      <td class="text-right">{{ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(asset.valorVenta) }}</td>
                    </tr>
                    <tr>
                      <td scope="row">TOTAL</td>
                      <td class="text-right">{{ new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(getQuoteData.total) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-row>
            <b-row no-gutters class="d-flex justify-content-between">
              <b-button
                cols="12"
                xs="12"
                sm="12"
                class="mr-2 mt-4 px-4"
                :style="`${'font-size: 17px; color: white; background-color:' + themeColors.primaryColor + '; border-color: ' +  themeColors.primaryColor}`"
                @click="redirectToSite(getQuoteData.pdfURL)"
              >
                Descargar cotización
              </b-button>
              <b-button
                cols="12"
                xs="12"
                sm="12"
                class="ml-2 mt-4 px-4"
                :style="`${'font-size: 17px; color: white; background-color:' + themeColors.primaryColor + '; border-color: ' +  themeColors.primaryColor}`"
                @click="newQuote()"
              >
                Nueva cotización
              </b-button>
            </b-row>
          </b-col>
          <b-col
            v-if="getProjectData.image"
            cols="12"
            sm="12"
            md="6"
            class="px-2 mt-2 d-flex align-items-center"
          >
            <b-container
              class="flex-fill"
              :style="
                `min-height: 450px; min-width: 450px; background-size: cover; background-repeat: no-repeat;
                background-position: center; background-image: url( ${getProjectData.image});`
              "
            >
            </b-container>
          </b-col>
        </b-row>
    </div>
  </b-row>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'

export default {
  name: 'ViewSuccess',
  computed: {
    ...mapState('general', ['themeColors']),
    ...mapState('opportunities', ['selectedOpportunity']),
    ...mapGetters('assets', ['getAddedAssets']),
    ...mapGetters('general', ['getProjectData', 'getIsChilean']),
    ...mapGetters('opportunities', ['getOpportunitiesSubAssets']),
    ...mapGetters('form', ['getQuoteData']),
    getSlang() {
      return this.getIsChilean ? ChileData : PeruData
    },
    assetName(){
      return `${this.selectedOpportunity.tipoBien} ${this.selectedOpportunity.numeroDeBien}`
    },
    cssVars() {
      return {
        '--primary-color': this.themeColors.primaryColor,
        '--secondary-color': this.themeColors.secondaryColor,
      }
    },
  },
  methods: {
    ...mapMutations('form', ['setFormSubmissionResponded']),
    ...mapMutations('assets', ['resetSecondaryAssetsToQuote']),
    newQuote() {
      this.setFormSubmissionResponded(false)
      this.resetSecondaryAssetsToQuote()
      this.$emit('close-modal')
    },
    redirectToSite(url) {
      window.open(url)
    },
    setTotal(){
      let allAssets = this.getAddedAssets.concat(this.getBAssociatedGroup)
      allAssets.push(this.selectedOpportunity)
      const discountData = this.selectedOpportunity['descuentoGrupo'].find(discount => discount['tipoDescuento'] == 'GRUPO_BIENES')
      allAssets = allAssets.concat(discountData['grupoBienes'].filter(asset => asset['id'] != this.selectedOpportunity['id']))
      allAssets = allAssets.map( (asset) => parseFloat(asset.bienMaestroDescuento ? asset.bienMaestroDescuento : asset.valorLista))
      const total = allAssets.reduce( (previous, current) => previous + current, 0 )
      return new Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(total)
    }
  },
}
</script>
<style scoped>
.primary-table-header{
  background-color: var(--primary-color) !important;
}
.focus-color{
  background-color: var(--secondary-color) !important;
}
</style>
