import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueCustomElement from 'vue-custom-element'
import Vuelidate from 'vuelidate'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faHome as fasHome,
	faBath as fasBath,
  faTrash as fasTrash,
  faBed as fasBed
} from '@fortawesome/pro-solid-svg-icons'
import {
  faBedFront as falBedFront,
  faBath as falBath,
  faRulerCombined as falRulerCombined,
  faDoorOpen as falDoorOpen,
} from '@fortawesome/pro-light-svg-icons'
import {
	faSearchPlus,
	faSearchMinus,
} from '@fortawesome/free-solid-svg-icons'
import InlineSvg from 'vue-inline-svg'
import Panzoom from '@panzoom/panzoom'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.config.productionTip = false
Vue.component('inline-svg', InlineSvg)
Object.defineProperty(Vue.prototype, '$panzoom', { value: Panzoom })

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})
const globalOptions = {
  dropdownOptions: {
    showDialCodeInList: false,
    showDialCodeInSelection: true,
    showFlags: true,
  },
  inputOptions:{
    placeholder: ' ',
  },
}

library.add(
	fasHome,
	fasBath,
	fasBed,
  fasTrash,
  faSearchPlus,
  faSearchMinus,
  falBedFront,
  falBath,
  falRulerCombined,
  falDoorOpen,
)

Vue.use(VueAxios, instance)
Vue.use(vueCustomElement)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueReCaptcha, {
	siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
	loaderOptions: {
		autoHideBadge: true,
	},
})
Vue.use(VueTelInput, globalOptions)

App.store = store

Vue.customElement('mobysuite-opportunities', App)
