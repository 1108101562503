import Vue from 'vue'
const state = {
  fetchedOpportunities: [],
  propertyNames: {
    assetNumber: 'numeroDeBien',
    roomsAmount: 'numeroDeDormitorios',
    bathroomsAmount: 'numeroDeBanos',
    assetType: 'tipoBien',
    floor: 'piso',
    id: 'id',
    imageURLOne: 'urlImagenUno',
    imageURLTwo: 'urlImagenDos',
    subAsset: 'bConjunto',
    totalArea: 'superficieTotal',
    terraceArea: 'superficieTerraza',
    utilArea: 'superficieUtil',
    orientation: 'orientacion',
    price: 'valorLista',
    type: 'tipo',
    masterAsset: 'bMaestro',
    projectId: 'proyecto',
    masterAssetTypes: ['CASA', 'DEPARTAMENTO', 'LOCAL', 'OFICINA', 'LOTE', 'ACCION'],
    assetGroup: 'grupoBienes',
		discountGroup: 'descuentoGrupo',
    discountType: 'tipoDescuento',
    associationType: 'tipoAsociacion',
    opportunityAssetsValidation: 'validarBienesOportunidad',
    associatedSubAssets: 'bAsociacion',
    associatedLimits: {
			max: 'maximo',
			min: 'minimo',
		},
  },
  useSecondaryImage: false,
  typeNames: {
    apartment: 'DEPARTAMENTO',
    parkingLot: 'ESTACIONAMIENTO',
    warehouse: 'BODEGA',
  },
  selectedOpportunity: null,
}
const mutations = {
  setOpportunities: (state, payload) => (state.fetchedOpportunities = payload),
  setSelectedOpportunity: (state, id) => {
    state.selectedOpportunity = state.fetchedOpportunities.find(opportunity => opportunity[state.propertyNames.id] == id)},
  setImagePreference: (state, value) => {state.useSecondaryImage = value},
}
const actions = {
  async fetchOpportunitiesData({ commit, rootState }) {
    const version = rootState.general.version == 2 ? 'v3' : 'v2'
        await Vue.axios
            .get(`${version}/opportunities/list-opportunities`, {
                params: {
                    customer: rootState.general.customer,
                    project: rootState.general.defaultProjectId
                },
            })
            .then(response => {
              const firstApartment = response.data.find(
                asset =>
                  state.propertyNames.masterAssetTypes.includes(asset[state.propertyNames.assetType])
              )
              if (!response.data.length || !firstApartment) {
                commit('general/setAtLeastOneVitalResponseFailed','', {root: true})
                commit('general/setErrorMessageTitle', "¡Lo sentimos!", {root: true})
                commit('general/setErrorMessage', "No se encontraron oportunidades por el momento. Atento próximamente a nuevos descuentos exclusivos.", {root: true})
              }else{
                commit('setOpportunities', response.data)
                commit('setSelectedOpportunity', firstApartment[state.propertyNames.id])
              }
              commit('general/setVitalDataLoaded', 'opportunities', { root: true })
            })
            .catch(error => {
              commit('general/setAtLeastOneVitalResponseFailed','', {root: true})
              commit('general/setVitalDataLoaded', 'opportunities', { root: true })
              console.log(error)
            })
    },
}

const getters = {
  getOpportunitiesInfo: state => state.fetchedOpportunities,
  getImageURL: (state, getters) => id => {
    const asset = getters.getAssetById(id)
    const URLValue = state.useSecondaryImage
      ? asset.urlImagenDos || null
      : asset.urlImagenUno || null
    const imageURL = URLValue
      ? URLValue
      : 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png'
    return imageURL
  },
  getAssetById: state => id => state.fetchedOpportunities.find(asset => asset[state.propertyNames.id] == id),
  getOpportunitiesSubAssets: (state, getters) => {
    let defaultAssetGroup = getters.getBAssociatedGroup.map(asset => asset[state.propertyNames.id])
    if(state.selectedOpportunity[state.propertyNames.discountGroup] && state.selectedOpportunity[state.propertyNames.discountGroup][0]){
      const discountData = state.selectedOpportunity[state.propertyNames.discountGroup].find(discount => discount[state.propertyNames.discountType] == 'GRUPO_BIENES')
      return discountData[state.propertyNames.assetGroup].filter(asset => asset[state.propertyNames.id] != state.selectedOpportunity[state.propertyNames.id] && !defaultAssetGroup.includes(asset[state.propertyNames.id]))
    }
    else {
      return []
    }
  },
  getOpportunityDiscount: state => state.selectedOpportunity[state.propertyNames.discountGroup].find(discount => discount[state.propertyNames.discountType] == 'GRUPO_BIENES'),
  getAssetsInfo: (state, getters, rootGetters) => {
    //Get assets info to show in quote table
    let allAssets = [
      state.selectedOpportunity
    ]
    allAssets = allAssets.concat(getters.getBAssociatedGroup)
    if (getters.getOpportunitiesSubAssets.length) {
      allAssets = allAssets.concat(getters.getOpportunitiesSubAssets)
    }
    if (rootGetters['assets/getAddedAssets']) {
      allAssets = allAssets.concat(rootGetters['assets/getAddedAssets'])
    }
    return allAssets.map(asset => ({
			name: `${asset[state.propertyNames.assetType]}  ${
				asset[state.propertyNames.assetNumber]
			}`,
			price: asset[state.propertyNames.price],
			discount:
				asset['descuentoGrupo'] && asset['descuentoGrupo'][0]
					? asset['descuentoGrupo'][0]
					: false,
			descuentoAutorizadoBien:
				asset['descuentoAutorizadoBien'] &&
				asset['descuentoAutorizadoBien'] != 0
					? asset['descuentoAutorizadoBien']
					: null,
			descuentoNoAutorizadoBien:
				asset['descuentoNoAutorizadoBien'] &&
				asset['descuentoNoAutorizadoBien'] != 0
					? asset['descuentoNoAutorizadoBien']
					: null,
			discountAmount: asset['descuentoOportunidad']
				? asset['descuentoOportunidad']
				: null,
			assetPriceWithDiscount:
				asset['descuentoGrupo'] && asset['descuentoGrupo'][0]
					? asset['descuentoGrupo'][0].calculoDscto
						? asset['descuentoGrupo'][0].calculoDscto
						: asset['bienMaestroDescuento']
						? asset['bienMaestroDescuento']
						: null
					: null,
      associationStatus:
        asset[state.propertyNames.associationType]
        ?  asset[state.propertyNames.associationType]
        : null
      ,
		}))
  },
  getAssetInfo: state => {
    let asset = state.selectedOpportunity
    return {
      name: `${asset[state.propertyNames.assetType]}  ${
				asset[state.propertyNames.assetNumber]
			}`,
			price: asset[state.propertyNames.price],
			discount:
				asset['descuentoGrupo'] && asset['descuentoGrupo'][0]
					? asset['descuentoGrupo'][0]
					: false,
			descuentoAutorizadoBien:
				asset['descuentoAutorizadoBien'] &&
				asset['descuentoAutorizadoBien'] != 0
					? asset['descuentoAutorizadoBien']
					: null,
			descuentoNoAutorizadoBien:
				asset['descuentoNoAutorizadoBien'] &&
				asset['descuentoNoAutorizadoBien'] != 0
					? asset['descuentoNoAutorizadoBien']
					: null,
			discountAmount: asset['descuentoOportunidad']
				? asset['descuentoOportunidad']
				: null,
			assetPriceWithDiscount:
				asset['descuentoGrupo'] && asset['descuentoGrupo'][0]
					? asset['descuentoGrupo'][0].calculoDscto
						? asset['descuentoGrupo'][0].calculoDscto
						: asset['bienMaestroDescuento']
						? asset['bienMaestroDescuento']
						: null
					: null,
      associationStatus:
        asset[state.propertyNames.associationType]
        ?  asset[state.propertyNames.associationType]
        : null
      ,
    }
  },
  getOpportunityAssetsValidation: (state, getters) => {
    return getters.getOpportunityDiscount[state.propertyNames.opportunityAssetsValidation]
    ? getters.getOpportunityDiscount[
      state.propertyNames.opportunityAssetsValidation
      ]
    : false
  },
  getBAssociatedGroup: (state) => state.selectedOpportunity.bConjunto ? state.selectedOpportunity.bConjunto.filter(asset => asset[state.propertyNames.id] != state.selectedOpportunity.id) : []
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
