<template>
		<b-row align="center" align-v="center">
			<b-col  style="min-width:100vh; min-height:100vh;" class="d-flex align-items-center" cols="12">
				<div style="min-width:100%">
          <h3 class="font-weight-light">
            {{getErrorMessage}}
          </h3>
          <br>
          <b-button
            class="ml-2 mt-4"
            :style="`${'font-size: 17px; color: white; background-color:' + themeColors.primaryColor + '; border-color: ' +  themeColors.primaryColor}`"
            @click="newQuote()">
            Regresar
          </b-button>
        </div>

			</b-col>
		</b-row>
</template>


<script>
	import { mapGetters, mapMutations, mapState } from 'vuex'
	export default{
		computed: {
			...mapGetters('form', ['getErrorMessage',]),
      ...mapState('general', ['themeColors']),
		},
    methods: {
      ...mapMutations('form', ['setFormSubmissionResponded', 'setErrorStatus']),
      ...mapMutations('assets', ['resetSecondaryAssetsToQuote']),
      newQuote() {
        this.setFormSubmissionResponded(false)
        this.resetSecondaryAssetsToQuote()
        this.setErrorStatus(false)
        this.$emit('close-modal')
      },
    },
	}
</script>
