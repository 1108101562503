<template>
  <div class="w-100 text-right">
    <b-button v-if="getAssetMaps" :style="cssVars" class="main-btn" @click="assetMapKey += 1; showAssetModal = true">+ Bienes complementarios</b-button>
    <transition name="modal" v-if="showAssetModal">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="showAssetModal = false">
          <div class="modal-container" @click.stop>
            <div class="modal-header">
              <b-container fluid>
                <b-row>
                  <b-col class="pr-0 text-left">
                    <h5>Seleccionar bienes
                    </h5>
                    <p style="font-size: small">
                      Haz click en el plano a aquellos bienes que quieres agregar en tu cotización
                    </p>
                  </b-col>
                  <b-col class="text-right">
                    <button type="button" aria-label="Close" class="close float-right" @click="showAssetModal = false">x</button>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <div class="modal-body">
              <slot name="body">
                <b-overlay :show="!assetImage" rounded="sm">
                  <b-container fluid>
                    <b-row>
                      <b-col cols="9" style="overflow: hidden;">
                        <div id="asset-container">
                          <inline-svg id="asset-image" v-if="assetImage" :src="assetImage" :key="assetMapKey" @loaded="setSvgTriggers()" />
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <h5>Seleccionar piso</h5>
                        <b-form-select class="my-2" v-model="selected" :options="getAssetMaps" @input="changeImage()"></b-form-select>
                        <b-button-group class="w-100" size="sm">
                          <b-button variant="info" id="zoom-out">
                            <font-awesome-icon :icon="['fas', 'search-minus']" />
                          </b-button>
                          <b-button variant="info" id="zoom-reset">REINICIAR</b-button>
                          <b-button variant="info" id="zoom-in">
                            <font-awesome-icon :icon="['fas', 'search-plus']" />
                          </b-button>
                        </b-button-group>
                        <b-form-checkbox v-if="subAssetsRequirements.warehouse.set" v-model="showWarehouses" name="check-button" switch
                        @change="
                              showWarehouses
                                ? setSubAssetsTypes({ action: 'add', type: 'BODEGA' })
                                : setSubAssetsTypes({ action: 'remove', type: 'BODEGA' })
                            ">
                          Mostrar bodegas
                        </b-form-checkbox>
                        <b-form-checkbox v-if="subAssetsRequirements.parkingLot.set" v-model="showParkingLots" name="check-button" switch
                        @change="
                              showParkingLots
                                ? setSubAssetsTypes({
                                    action: 'add',
                                    type: 'ESTACIONAMIENTO',
                                  })
                                : setSubAssetsTypes({
                                    action: 'remove',
                                    type: 'ESTACIONAMIENTO',
                                  })
                            ">
                          Mostrar estacionamientos
                        </b-form-checkbox>
                        <h5 class="mt-5 text-center" v-if="selectedAssets">Bienes seleccionados</h5>
                        <b-table-simple small>
                            <b-tr v-for="(asset) in selectedAssets" v-bind:key="'asset'+asset.id">
                                <th>
                                    {{asset.tipoBien}}
                                </th>
                                <td class="text-right">
                                    {{asset.numeroDeBien}}
                                </td>
                                <td class="text-right">
                                    <b-button variant="danger" size="sm" @click="removeItem(asset.id)"><font-awesome-icon :icon="['fas', 'trash']"/></b-button>
                                </td>
                            </b-tr>
                        </b-table-simple>
                        <b-button block class="add-assets-btn" @click="showAssetModal = false">
                          + Agregar bienes
                        </b-button>
                        <b-alert v-model="showMaxNumberAlert" variant="danger" dismissible class="my-3">
                          <h4 class="alert-heading">Error</h4>
                          <div v-if="!subAssetsGlobalMaxNumber">
                            <p  class="text-justify" style="font-size: 16px;" v-if="warehouseDisabled">No puede seleccionar más de {{getWarehousesMaxNumber}} bodega(s)</p>
                            <p  class="text-justify" style="font-size: 16px;" v-if="parkingDisabled">No puede seleccionar más de {{getParkingLotsMaxNumber}} estacionamiento(s)</p>
                          </div>
                          <p v-else class="text-justify" style="font-size: 16px;">No puede seleccionar más de {{subAssetsGlobalMaxNumber}} bienes complementarios con esta oportunidad</p>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-overlay>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
  export default {
    name: 'AssetMap',
    props: {
      initialAssets: {default: []},
      color: { type:String, default: null},
    },
    data(){
      return{
        showAssetModal: false,
        clickedElement: null,
        assets: null,
        showSvg: false,
        assetImage: null,
        assetTypes: [],
        images: [],
        selected: null,
        asset: null,
        selectedAssets: [],
        selectedWarehouses: [],
        selectedParkingLots: [],
        warehouseDisabled: false,
        parkingDisabled: false,
        showMaxNumberAlert: false,
        assetMapKey: 0,
        showWarehouses: true,
        showParkingLots: true,
        subAssetsGlobalMaxNumber: null,
        subAssetsGlobalMinNumber: null,
      }
    },
    mounted(){
      if(!this.subAssetsRequirements.parkingLot.set){
        this.setSubAssetsTypes({ action: 'remove', type: 'ESTACIONAMIENTO'})
      } else {
        this.setSubAssetsTypes({ action: 'add', type: 'ESTACIONAMIENTO'})
      }
      if(!this.subAssetsRequirements.parkingLot.set){
        this.setSubAssetsTypes({ action: 'remove', type: 'BODEGA'})
      } else {
        this.setSubAssetsTypes({ action: 'add', type: 'BODEGA'})
      }
      this.showParkingLots = this.showSubAssetType('ESTACIONAMIENTO')
      this.showWarehouses = this.showSubAssetType('BODEGA')
    },
    methods: {
      ...mapMutations('assets', [
        'setAddedAssets',
        'setAddedParkingLots',
        'setAddedWarehouses',
        'setSubAssetsTypes',
      ]),
      ...mapMutations('general', [
        'setIsMinParkingLotsNumberFulfilled',
        'setIsMinWarehouseNumberFulfilled',
        'setloadingPaymentPlan'
      ]),
      ...mapMutations('form', ['setIsButtonDisabled',]),
      ...mapActions('general', ['fetchPaymentPlan']),
      removeItem(idBien){
        let asset = this.getAssetById(idBien)
        let assetId = asset.id
        this.selectedAssets = this.selectedAssets.filter( asset => asset.id !== assetId)
        this.selectedWarehouses = this.selectedWarehouses.filter( asset => asset.id !== assetId)
        this.selectedParkingLots = this.selectedParkingLots.filter( asset => asset.id !== assetId)
        if(this.subAssetsGlobalMaxNumber != null){
          this.parkingDisabled = false
          this.warehouseDisabled = false
          this.showMaxNumberAlert = false
        } else {
          if(asset[this.propertyNames.assetType] == 'ESTACIONAMIENTO'){
            this.parkingDisabled = false
          } else{
            this.warehouseDisabled = false
          }
          this.showMaxNumberAlert = false
        }
        this.validateMins()
        this.paintElements()
      },
      changeImage(){
        this.assetImage = this.selected;
        this.clickedElement = null;
      },
      validateMins(){
        if(((this.selectedWarehouses.length >= this.getWarehousesMinNumber ||
          this.getWarehousesMinNumber === null) && this.subAssetsGlobalMinNumber == null) || (this.subAssetsGlobalMinNumber != null && this.selectedAssets.length >= this.subAssetsGlobalMinNumber))
            this.setIsMinWarehouseNumberFulfilled(true)
        else
          this.setIsMinWarehouseNumberFulfilled(false)
        if(((this.selectedParkingLots.length >= this.getParkingLotsMinNumber ||
          this.getParkingLotsMinNumber === null) && this.subAssetsGlobalMinNumber == null) || (this.subAssetsGlobalMinNumber != null && this.selectedAssets.length >= this.subAssetsGlobalMinNumber) )
            this.setIsMinParkingLotsNumberFulfilled(true)
        else
          this.setIsMinParkingLotsNumberFulfilled(false)
        if(this.getIsMinParkingLotsNumberFulfilled && this.getIsMinWarehouseNumberFulfilled)
          this.setIsButtonDisabled(false)
      },
      print(){
        window.print();
      },
      setZoomControls(svgDoc){
        if(svgDoc){
          const panzoom = this.$panzoom(svgDoc, {
            maxScale: 10
          });
          document.getElementById('zoom-in').addEventListener('click', panzoom.zoomIn)
          document.getElementById('zoom-out').addEventListener('click', panzoom.zoomOut)
          document.getElementById('zoom-reset').addEventListener('click', panzoom.reset)
          svgDoc.parentElement.addEventListener('wheel', function(event) {
            panzoom.zoomWithWheel(event)
          })
        }
      },
    setSvgTriggers() {
      if (this.assets) {
        let self = this
        self.showSvg = true
        let svgDoc = document.getElementById('asset-image')
        this.setZoomControls(svgDoc)
        let elements =
          svgDoc.querySelectorAll('#asset-elements g').length == 0
            ? svgDoc.querySelector('#asset-elements').children
            : svgDoc.querySelectorAll('#asset-elements g')
        let assetElement = null
        elements.forEach(function(asset) {
          asset.id = asset.id.replace('_', '')
          assetElement = self.getAssetById(asset.id)
          let drawPaths = function() {
            self.paintElements()
            let path =
              typeof asset.getElementsByTagName('rect')[0] == 'undefined'
                ? asset.getElementsByTagName('path')[0]
                : asset.getElementsByTagName('rect')[0]
            path = typeof path == 'undefined' ? asset : path
            if (
              path.getAttribute('data-state') == 'DISPONIBLE' &&
              self.getAssetById(this.id)
            ) {
              if (
                self.selectedAssets.find(selAsset => selAsset.id == asset.id)
              ) {
                self.removeItem(parseInt(asset.id))
              } else {
                self.clickedElement = this.id
                self.validateMins()
                if (
                  self.getAssetById(self.clickedElement).tipoBien ==
                  'ESTACIONAMIENTO'
                ) {
                  if (
                    self.selectedParkingLots.length <
                      self.parkingLotsMaxNumber ||
                    self.parkingLotsMaxNumber === null
                  ) {
                    path.style.fill = 'rgb(173, 216, 230)'
                  }
                }
                if (
                  self.getAssetById(self.clickedElement).tipoBien == 'BODEGA'
                ) {
                  if (
                    self.selectedWarehouses.length < self.warehousesMaxNumber ||
                    self.warehousesMaxNumber === null
                  ) {
                    path.style.fill = 'rgb(173, 216, 230)'
                  }
                }
                let assetObject = self.getAssetById(asset.id)
                if (assetObject.bConjuntos) {
                  assetObject.bConjuntos
                    .map(asset => asset.id)
                    .forEach(function(asset) {
                      if (
                        !self.selectedAssets.find(
                          selAsset => selAsset.id == asset.id
                        )
                      ) {
                        self.selectedAssets.push(
                          self.getAssetById(self.clickedElement)
                        )
                      }
                      let paintedElement = document.getElementById(asset)
                      if (paintedElement) {
                        let path =
                          typeof paintedElement.getElementsByTagName(
                            'rect'
                          )[0] == 'undefined'
                            ? paintedElement.getElementsByTagName('path')[0]
                            : paintedElement.getElementsByTagName('rect')[0]
                        path =
                          typeof path == 'undefined' ? paintedElement : path
                        path.style.fill = 'rgb(173, 216, 230)'
                      }
                    })
                }
              }
            }
          }
          if (assetElement) {
            asset.id = assetElement.id
            if (self.initialAssets.includes(assetElement.id)) {
              let path =
                typeof asset.getElementsByTagName('rect')[0] == 'undefined'
                  ? asset.getElementsByTagName('path')[0]
                  : asset.getElementsByTagName('rect')[0]
              path = typeof path == 'undefined' ? asset : path
              path.setAttribute('data-color', 'rgb(108, 117, 125)')
              path.style.fill = 'rgb(108, 117, 125)'
            } else {
              let permited = true
              let bConjuntos = []
              if (assetElement.bConjunto) {
                permited = assetElement.bConjunto.filter(
                  asset => asset.tipoBien == 'DEPARTAMENTO'
                )
                  ? false
                  : true
                bConjuntos = assetElement.bConjunto.map(asset => asset.id)
              }
              if (permited) {
                asset.style.cursor =
                  assetElement.estadoBien != 'DISPONIBLE'
                    ? 'not-allowed'
                    : 'pointer'
                let path =
                  typeof asset.getElementsByTagName('rect')[0] == 'undefined'
                    ? asset.getElementsByTagName('path')[0]
                    : asset.getElementsByTagName('rect')[0]
                path = typeof path == 'undefined' ? asset : path
                path.setAttribute('data-color', 'rgb(6, 224, 15)')
                path.setAttribute('data-state', assetElement.estadoBien)
                path.setAttribute('data-type', assetElement.tipo)
                if (bConjuntos.length > 0) {
                  path.setAttribute('data-added', bConjuntos)
                }
                path.style.stroke = 'transparent'
                path.style.strokeWidth = '7px'
                asset.addEventListener('click', drawPaths, false)
              }
            }
          } else {
            let path =
              typeof asset.getElementsByTagName('rect')[0] == 'undefined'
                ? asset.getElementsByTagName('path')[0]
                : asset.getElementsByTagName('rect')[0]
            path = typeof path == 'undefined' ? asset : path
            path.style.fill = 'transparent'
            asset.style.cursor = 'not-allowed'
          }
        }, false)
        self.paintElements()
        }
      },
      setFilter(){
        for (let color in this.filter.showColors) {
          if (!this.filter.showColors[color]) this.filter.showAllColors = false
        }
        this.paintElements()
      },
      paintElements(){
        let self = this
        let svgDoc = document.getElementById('asset-image')
        let elements =
          svgDoc.querySelectorAll('#asset-elements g').length == 0
            ? svgDoc.querySelector('#asset-elements').children
            : svgDoc.querySelectorAll('#asset-elements g')
        elements.forEach(function(asset) {
          let path =
            typeof asset.getElementsByTagName('rect')[0] == 'undefined'
              ? asset.getElementsByTagName('path')[0]
              : asset.getElementsByTagName('rect')[0]
          path = typeof path == 'undefined' ? asset : path
          let assetObject = self.getAssetById(asset.id)
          if (assetObject) {
            if (self.initialAssets.includes(assetObject.id)) {
              path.style.fill = 'rgb(108, 117, 125)'
            } else {
              if (assetObject.estadoBien == 'DISPONIBLE') {
                path.style.fill = 'rgb(6, 224, 15)'
              } else {
                path.style.fill = 'transparent'
              }
              if (self.selectedAssets) {
                if (
                  self.selectedAssets.find(
                    selAsset => selAsset.id == assetObject.id
                  )
                ) {
                  path.style.fill = 'rgb(173, 216, 230)'
                }
              }
            }
          }
        }, false)
      },
      showSubAssetType(type) {
        return this.getSubAssetsNames.includes(type)
      },
    },
    watch: {
      showAssetModal(){
        if(this.showAssetModal) {
          this.selectedAssets = this.getAddedAssets;
          this.selectedWarehouses = this.getAddedWarehouses;
          this.selectedParkingLots = this.getAddedParkingLots;
          this.validateMins()
        } else {
          this.setAddedAssets(this.selectedAssets);
          this.setAddedWarehouses(this.selectedWarehouses);
          this.setAddedParkingLots(this.selectedParkingLots);
          this.selectedAssets = [];
          this.selectedWarehouses = [];
          this.selectedParkingLots = [];
          this.showMaxNumberAlert = false;
          this.setloadingPaymentPlan(true)
          this.fetchPaymentPlan()
        }
      },
      clickedElement() {
        if(this.clickedElement){
            let asset = this.getAssetById(this.clickedElement);
            if(this.subAssetsGlobalMaxNumber != null && this.selectedAssets.length == this.subAssetsGlobalMaxNumber){
              this.parkingDisabled = true
              this.warehouseDisabled = true
              this.showMaxNumberAlert = true
            }
            else {
              if(this.getAssetById(this.clickedElement).tipoBien == 'ESTACIONAMIENTO'){
                if(
                  ((this.selectedParkingLots.length < this.getParkingLotsMaxNumber ||
                    this.getParkingLotsMaxNumber === null || !this.subAssetsRequirements.parkingLot.required) && this.subAssetsGlobalMaxNumber == null) ||
                  (this.subAssetsGlobalMaxNumber != null && !this.parkingDisabled)
                  )
                {
                  if(!this.selectedAssets.find( selAsset => selAsset.id == asset.id )){
                      this.selectedAssets.push(this.getAssetById(this.clickedElement))
                      this.selectedParkingLots.push(this.getAssetById(this.clickedElement))
                      this.validateMins()
                  }
                }
                else{
                  if(this.subAssetsGlobalMaxNumber == null){
                    this.warehouseDisabled = false
                    this.parkingDisabled = true
                    this.showMaxNumberAlert = true
                  }

                }
              }
              else if(this.getAssetById(this.clickedElement).tipoBien == 'BODEGA'){
                if(
                  ((this.selectedWarehouses.length < this.getWarehousesMaxNumber ||
                    this.getWarehousesMaxNumber === null || !this.subAssetsRequirements.warehouse.required) && this.subAssetsGlobalMaxNumber == null) ||
                    (this.subAssetsGlobalMaxNumber != null && !this.warehouseDisabled)
                  )
                {
                  if(!this.selectedAssets.find( selAsset => selAsset.id == asset.id )){
                      this.selectedAssets.push(this.getAssetById(this.clickedElement))
                      this.selectedWarehouses.push(this.getAssetById(this.clickedElement))
                      this.validateMins()
                  }
                }
                else{
                  if(this.subAssetsGlobalMaxNumber == null){
                    this.parkingDisabled = false
                    this.warehouseDisabled = true
                    this.showMaxNumberAlert = true
                  }

                }
              }
            }

        }
      },
      getAssetMaps: {
        handler: function()  {
          if(this.getAssetMaps){
            this.selected = this.getAssetMaps[0].value
            this.changeImage()
          }
        },
        immediate: true
      },
      getAssets: {
        handler: function()  {
          if(this.getAssets){
            this.assets = this.getAssets
            this.changeImage()
            this.assetMapKey += 1
          }
        },
        immediate: true
      },
      selectedOpportunity: {
        handler: function()  {
          if(this.selectedOpportunity[this.propertyNames.associatedLimits.max]){
            this.subAssetsGlobalMaxNumber = this.selectedOpportunity[this.propertyNames.associatedLimits.max]
            this.subAssetsGlobalMinNumber = this.selectedOpportunity[this.propertyNames.associatedLimits.min]
          } else{
            this.subAssetsGlobalMaxNumber = null
            this.subAssetsGlobalMinNumber = null
          }
        },
        immediate: true
      }
    },
    computed: {
      ...mapGetters('assets', [
        'getAssets',
        'getAssetById',
        'getAddedAssets',
        'getAddedParkingLots',
        'getAddedWarehouses',
        'getSubAssetsNames',
        ]),
      ...mapGetters('general', [
        'getAssetMaps',
        'getWarehousesMaxNumber',
        'getWarehousesMinNumber',
        'getParkingLotsMaxNumber',
        'getParkingLotsMinNumber',
        'getIsMinParkingLotsNumberFulfilled',
        'getIsMinWarehouseNumberFulfilled',
        ]),
      ...mapState('form', ['subAssetsRequirements']),
      ...mapState('opportunities', ['selectedOpportunity', 'propertyNames']),
      cssVars() {
        return {
          '--primary-color': this.color,
        }
      },
    }
  }
</script>
<style scoped>
.main-btn{
  border-color: 2px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: white !important;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #000000
}
.add-assets-btn {
  border-color: 2px solid #555 !important;
  color: #FFF !important;
  background-color: rgb(67, 64, 64) !important;
  border-radius: 16px;
  box-shadow: 2px 2px 4px #000000;
  min-width: 220px;
  min-height: 35px;
}
</style>
