import Vue from 'vue'
import Vuex from 'vuex'
import assets from '@/store/modules/assets'
import opportunities from '@/store/modules/opportunities'
import general from '@/store/modules/general'
import form from '@/store/modules/form'

Vue.use(Vuex)

const modules = {
	assets,
	opportunities,
    general,
	form,
}

export default new Vuex.Store({
	modules,
})